import { DocumentCreateResultBase64, IDocumentCreateResultBase64 } from "./documentCreateResultBase64";

export interface IDocumentCreateWithPreviewResult extends IDocumentCreateResultBase64 {
    pages: string[];
}

export class DocumentCreateWithPreviewResult extends DocumentCreateResultBase64 implements IDocumentCreateWithPreviewResult {
    pages: string[];

    constructor();
    constructor(obj: IDocumentCreateWithPreviewResult);
    constructor(obj?: any) {
        super(obj);
        this.pages = obj && obj.pages || [];
    }
}