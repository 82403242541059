import { DocumentCard, Image, ImageFit, Stack } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import { ExcelHelper } from "../../models/excel/excelHelper";
import AppTooltip from "../common/AppTooltip";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { getUseCaseBase64Content } from "../../models/services/useCaseService";
import { callService } from "../../models/common/appUtils";

//Styles
const StyledDocumentCard = styled(DocumentCard)`
    margin-bottom: 10px;
    cursor: pointer;
    
    &:hover {
        background-color: #e8e8e8;
    }
`

const StyledDocumentCardContent = styled.div`
    padding: 5px;
`

const StyledImage = styled(Image)`
    margin-right: 5px;
    
`
const StyledDocumentCardTitle = styled(Stack)`
    font-size: 20px;
    padding-bottom: 5px;
    align-items: center;
    
`

//Component
export interface IUseCaseListItemProps {
    item: IUseCaseListItem;
    refreshList: () => void;
}

export const UseCaseListItem = (props: IUseCaseListItemProps) => {

    const handleOpenUseCaseExcel = async () => {
        if (!props.item) return;
        const content = await callService(() => getUseCaseBase64Content(props.item.id), false)
        if (!content) return;
        await ExcelHelper.createNewWorkbook(content);
    }

    return (
        <StyledDocumentCard onClick={handleOpenUseCaseExcel}>
            <AppTooltip
                text={props.item.tip}
            >
                <StyledDocumentCardContent>
                    <StyledDocumentCardTitle horizontal>
                        {!!props.item.image &&
                            <StyledImage
                                src={"data:image/jpg;base64," + props.item.image} 
                                height={50}
                                width={50}
                                imageFit={ImageFit.cover}
                            />
                        }
                        <div>{props.item.name}</div>
                    </StyledDocumentCardTitle>
                    {!!props.item.description &&
                        <Stack horizontal>
                            <div>{props.item.description}</div>
                        </Stack>
                    }
                </StyledDocumentCardContent>
            </AppTooltip>
        </StyledDocumentCard>
    );
}

export default UseCaseListItem;
