import { Base } from "../../framework/base";
import { ITemplate, Template } from "../template/template";

export interface ITemplateGroup {
    code: string;
    name: string;
    templates: ITemplate[];
}

export class TemplateGroup implements ITemplateGroup {
    code: string;
    name: string;
    templates: Template[];

    constructor();
    constructor(obj: ITemplateGroup);
    constructor(obj?: any) {
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.templates = Base.getTypedArray(obj ? obj.templates : null, Template);
    }

    static getGroups(templates: Template[]): TemplateGroup[] {
        templates.sort((a: Template, b: Template) => {
            if (!a || !b) return 0;
            const groupValue = Base.strCompare(a.group, b.group);
            return groupValue !== 0 ? groupValue : Base.strCompare(a.code, b.code);
        });
        const result: TemplateGroup[] = [];
        let currentGroup: TemplateGroup = null;
        for (const template of templates) {
            //if (template.samples.length === 0) continue;
            //if (template.formats.indexOf(formatDocxCode) < 0) continue;
            if (!currentGroup || currentGroup.code !== template.group) {
                currentGroup = new TemplateGroup();
                currentGroup.code = template.group;
                currentGroup.name = template.groupName;
                result.push(currentGroup);
            }
            currentGroup.templates.push(template);
        }
        return result;
    }
}