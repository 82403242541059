import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import React from "react";
import { Translations } from "../../models/common/translations";
import { IDropdownOption, IconButton, ImageFit, PartialTheme, Pivot, PivotItem, Stack } from "@fluentui/react";
import FormTextField from "../common/FormTextField";
import FormLine from "../common/FormLine";
import TitleBar from "../common/TitleBar";
import { IUseCaseEdit } from "../../models/useCase/useCaseEdit";
import { getUseCaseEdit, getUseCaseExcelFile, removeUseCase, saveUseCase } from "../../models/services/useCaseService";
import { callService, callServiceOnRemove, callServiceOnSave, handleFormDialogClose, navigateBackWithReturnUrl, showConfirmationDialog, showErrorMessage, showSuccessMessage } from "../../models/common/appUtils";
import FormIntField from "../common/FormIntField";
import FormDropdown from "../common/FormDropdown";
import { Base } from "../../framework/base";
import { FormImageField } from "../common/FormImageField";
import styled from "@emotion/styled";
import { ExcelFileSettings } from "../../models/excel/excelFileSettings";
import FormBoolenField from "../common/FormBoolenField";
import saveAs from "file-saver";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { UseCaseGenerationParameters } from "../../models/useCase/useCaseGenerationParameters";
import { FormFileField } from "../common/FormFileField";
import { ConfirmationDialogResult, LanguageCode } from "../../models/common/enums";
import { pivotStyles } from "../appTheme";
import { ExcelHelper } from "../../models/excel/excelHelper";
import { Base64 } from "js-base64";
import { xlsxContentType } from "../../models/common/consts";
import { appActions } from "../../store/app";
import { getTemplateGroups } from "../../models/services/templateService";

//Styles
const NameFormTextField = styled(FormTextField)`
    flex-grow: 1;
    margin-left: 10px;
`

const FileNameContainer = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-left: 10px;
`

//Component
interface IFormData { 
    name: string;
    description: string;
    tip: string;
    sortOrder: number;
    useCaseDataId: string;
    //Aiddocs
    ownerCode: string;
    username: string;
    password: string;
    context: string;
    templateGroup: string;
    //Parameter Data
    rootElementName: string;
    omitElementsWithPrefix: string;
    useCollectionElements: boolean;
    allowMultiple: boolean;
    autoOpenTaskPane: boolean;
}

const UseCaseEdit = () => {
    const navigate = useNavigate();
    let { id } = useParams();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const [useCaseEdit, setUseCaseEdit] = useState<IUseCaseEdit>(null);
    const [useCaseDatas, setUseCaseDatas] = useState<IDropdownOption[]>([]);
    const [tgOptions, setTgOptions] = useState<IDropdownOption[]>([]);
    const [image, setImage] = useState<string>(null);
    const [file, setFile] = useState<File>(null);
    const [excel, setExcel] = useState<string>(null);
    const [excelFile, setExcelFile] = useState<File>(null);
    const [excelName, setExcelName] = useState<string>(null);
    const dispatch = useAppDispatch();

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState, reset } = useForm({
        defaultValues: {
            name: "",
            description: "",
            tip: "",
            sortOrder: 0,
            useCaseDataId: Base.emptyGuid,
            context: "",
            templateGroup: "",
            rootElementName: "",
            omitElementsWithPrefix: "",
            useCollectionElements: false,
            allowMultiple: false,
            autoOpenTaskPane: false,
        }
    });
    
    const { isDirty } = formState;

    const loadUseCase = async () => {
        const edit = await callService(() => getUseCaseEdit(id))
        if (!edit) return
        setUseCaseEdit(edit);
        loadTemplateGroups();
        const item = edit.item;
        setImage(item.image ? "data:image/jpg;base64," + item.image : null);
        const generationParameters = new UseCaseGenerationParameters(item.generationParameters);
        reset({
            name: item.name ?? "",
            description: item.description ?? "",
            tip: item.tip ?? "",
            sortOrder: item.sortOrder ?? 0,
            useCaseDataId: item.useCaseDataId ? item.useCaseDataId : Base.emptyGuid,
            context: generationParameters.context,
            templateGroup: generationParameters.templateGroup,
            rootElementName: generationParameters.rootElementName,
            omitElementsWithPrefix: generationParameters.omitElementsWithPrefix,
            useCollectionElements: generationParameters.useCollectionElements,
            allowMultiple: generationParameters.allowMultiple,
            autoOpenTaskPane: generationParameters.autoOpenTaskPane,
        });
        var options = edit.useCaseDatas.map(i => {
            return { key: i.id, text: i.name }
        });
        options.unshift({ key: Base.emptyGuid, text: Translations.NotInUse });
        setUseCaseDatas(options);
        setExcelName(item.excelFileName);
    }

    const loadTemplateGroups = async () => {
        dispatch(appActions.showSpinner());
        try {
            const newTemplateGroups = await getTemplateGroups(LanguageCode.Fi, "");
            if ( !newTemplateGroups) return;
                    var templateGroupOptions = newTemplateGroups.items.map(i => {
                        return { key: i.code, text: i.name}
                    });
                    templateGroupOptions.unshift({ key:"", text: Translations.All });
                    setTgOptions(templateGroupOptions);
        } catch (error) {
            console.log("loadTemplateGroups", error);
            dispatch(appActions.showError(error.toString()));
        } finally {
            dispatch(appActions.hideSpinner());
        }
    }

    useEffect(() => {
        loadUseCase();
    }, []);

    const handleImageChange = async (filePar: File) => {
        if (!filePar) return;
        const base64 = await Base.blobToBase64(filePar);
        if (!base64) return;
        setFile(filePar);
        setImage(base64);
    }

    const handleFileDownload = async () => {
        if (!excelName) return;
        if (excelFile) {
            saveAs(excelFile, excelName);
            return;
        }
        const newExcelFile = await callService(() => getUseCaseExcelFile(id), false)
        if (!newExcelFile) return
        saveAs(newExcelFile, excelName);
    }

    const handleFileUpload = async (filePar: File) => {
        if (!filePar) return;
        const base64 = await Base.blobToBase64(filePar);
        if (!base64) return;
        setExcel(base64);
        setExcelFile(filePar);
        setExcelName(filePar.name);
    }

    const handleExcelUpload = async () => {
        ExcelHelper.getDocumentAsBase64((bytes: Uint8Array, error: string) => {
            if (!bytes?.length) {
                if (error) {
                    showErrorMessage(Translations.ReadingExcelFailed + " " + Translations.Error + ": '" + error + "'")
                }
                return;
            }
            const base64 = Base64.fromUint8Array(bytes);
            if (!base64) return;
            const file = Base.blobToFile(Base.base64ToBlob(base64, xlsxContentType), excelName);
            setExcel(base64);
            setExcelFile(file);
            showSuccessMessage(Translations.ExcelFileUpdated);
        });
    }    

    const closeView = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    }

    const handleClose = () => {
        handleFormDialogClose(isDirty, () => handleSubmit(onSubmit)(), closeView);
    }

    //If opened from use case open view, set settings also to excel
    const setFormDataToExcel = (data: IFormData) => {
        if (!returnUrl) return;
        var excelSettings = new ExcelFileSettings();
        excelSettings.useCaseId = useCaseEdit.item.id;
        excelSettings.autoOpenTaskPane = data.autoOpenTaskPane;
        ExcelFileSettings.saveSettings(excelSettings);
    }

    const onSubmit = async (data: IFormData) => {
        const saveData = {
            ...data,
            id: useCaseEdit.item.id,
            rowId: useCaseEdit.item.rowId,
            image: Base.removeDataUrlHeader(image),
            useCaseDataId: data.useCaseDataId === Base.emptyGuid ? null : data.useCaseDataId,
            excel: excel ? Base.removeDataUrlHeader(excel) : null,
            excelFileName: excel ? excelName : null,
        };
        const result = await callServiceOnSave(() => saveUseCase(saveData))
        if (!result) return;
        setFormDataToExcel(data);
        closeView();
    }

    const handleRemove = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.UseCaseRemoveConfirmation, useCaseEdit.item.name), Translations.RemoveUseCase);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeUseCase(id))
        if (!dbResult) return;
        closeView();
    }
    
    if (!useCaseEdit) return null;

    return (
        <div>
            <TitleBar
                title={id !== Base.emptyGuid ? Translations.EditUseCase : Translations.NewUseCase}
            >
                <Stack horizontal className="buttons">
                    <IconButton iconProps={{ iconName: "Save" }} title={Translations.Save} ariaLabel={Translations.Save} onClick={handleSubmit(onSubmit)} />
                    <IconButton iconProps={{ iconName: "Remove" }} title={Translations.Remove} ariaLabel={Translations.Remove} onClick={handleRemove} />
                    <IconButton iconProps={{ iconName: "ChromeClose" }} title={Translations.Close} ariaLabel={Translations.Close} onClick={handleClose} />
                </Stack>
            </TitleBar>
            <Pivot
                styles={pivotStyles}
            >
                <PivotItem
                    headerText={Translations.Basic}
                    
                >
                    <FormLine>
                        <Stack horizontal>
                            <FormImageField
                                src={image} 
                                height={60}
                                width={60}
                                imageFit={ImageFit.cover}
                                onFileChange={handleImageChange}
                            />
                            <NameFormTextField
                                label={Translations.Name}
                                name="name"
                                control={control}
                                required 
                                maxLength={50}
                                autoFocus                        
                                rules={{ required: Translations.NameIsRequired }}
                            />
                        </Stack>
                    </FormLine>
                    <FormLine>
                        <FormFileField
                             accept={".xlsx"}
                             fileName={excelName}
                             label={Translations.ExcelFile}
                             onFileDownload={handleFileDownload}
                             onFileUpload={handleFileUpload}
                             onExcelUpload={handleExcelUpload}
                        />
                    </FormLine>            
                    <FormLine>
                        <FormTextField
                            label={Translations.Description}
                            name="description"
                            control={control}
                            maxLength={1000}
                            multiline rows={5} 
                        />
                    </FormLine>
                    <FormLine>
                        <FormTextField
                            label={Translations.ToolTip}
                            name="tip"
                            control={control}
                            maxLength={1000}
                            multiline rows={5} 
                        />
                    </FormLine>
                    <FormLine>
                        <FormDropdown
                            label={Translations.Data}
                            name="useCaseDataId"
                            control={control}
                            options={useCaseDatas}
                        />
                    </FormLine>
                    <FormLine>
                        <FormIntField
                            label={Translations.SortOrder}
                            name="sortOrder"
                            control={control}
                            min={0}
                            max={10000}
                            step={1}
                        />
                    </FormLine>
                </PivotItem>
                <PivotItem headerText={Translations.Aiddocs}>
                    <FormLine>
                        <FormTextField
                            label={Translations.AiddocsCallContext}
                            name="context"
                            control={control}
                            maxLength={1000}
                            multiline rows={5} 
                        />
                    </FormLine>
                    <FormLine>
                    <FormDropdown
                            label={Translations.TemplateGroup}
                            name="templateGroup"
                            control={control}
                            options={tgOptions}
                        />
                    </FormLine>            
                </PivotItem>
                <PivotItem headerText={Translations.ParameterData}>
                    <FormLine>
                        <FormTextField
                            label={Translations.RootElementName}
                            name="rootElementName"
                            control={control}
                            maxLength={50}
                            rules={{
                                validate: (value) => {
                                    if (!Base.isValidXmlElementName(value)) {
                                        return Translations.RootElementNameIsInvalid;
                                    }
                                    return null;
                                }
                            }}
                        />
                    </FormLine>
                    <FormLine>
                        <FormTextField
                            label={Translations.OmitElementsWithPrefix}
                            name="omitElementsWithPrefix"
                            control={control}
                            maxLength={50}
                        />
                    </FormLine>            
                    <FormLine>
                        <FormBoolenField
                            label={Translations.UseCollectionElements}
                            name="useCollectionElements"
                            control={control}
                        />
                    </FormLine>
                    <FormLine>
                        <FormBoolenField
                            label={Translations.AllowMultiple}
                            name="allowMultiple"
                            control={control}
                        />
                    </FormLine>
                    <FormLine>
                        <FormBoolenField
                            label={Translations.AutoOpenAddinWhenDocumentIsOpened}
                            name="autoOpenTaskPane"
                            control={control}
                        />
                    </FormLine>
                </PivotItem>
            </Pivot>
        </div>
    )
}

export default UseCaseEdit;