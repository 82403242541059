import { IconButton, Stack } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ApplicationSettings, IApplicationSettings } from "../../models/common/applicationSettings";
import { Translations } from "../../models/common/translations";
import TitleBar from "../common/TitleBar";
import FormLine from "../common/FormLine";
import { updateServiceRootUrl } from "../../models/services/baseService";
import FormTextField from "../common/FormTextField";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { handleFormDialogClose, navigateBackWithReturnUrl } from "../../models/common/appUtils";

//Styles

//Component
export const AppSettings = () => {
    const navigate = useNavigate();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);
    const [settings, setSettings] = useState<IApplicationSettings>(ApplicationSettings.readSettings());

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            serviceUrl: settings.serviceUrl
        }
    });
    
    const { isDirty } = formState;

    const closeView = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    }    
    
    const handleClose = () => {
        handleFormDialogClose(isDirty, () => handleSubmit(onSubmit)(), closeView);
    }    

    const onSubmit = async (data: { serviceUrl: string }) => {
        const newSettings = new ApplicationSettings(settings);
        newSettings.serviceUrl = data.serviceUrl;
        ApplicationSettings.saveSettings(newSettings);
        updateServiceRootUrl(newSettings.serviceUrl);
        closeView();
    }

    return (
        <div>
            <TitleBar
                title={Translations.Settings}
            >
                <Stack horizontal className="buttons">
                    <IconButton iconProps={{ iconName: "Save" }} title={Translations.Save} ariaLabel={Translations.Save} onClick={handleSubmit(onSubmit)} />
                    <IconButton iconProps={{ iconName: "ChromeClose" }} title={Translations.Close} ariaLabel={Translations.Close} onClick={handleClose} />
                </Stack>                
            </TitleBar>
            <FormLine>
                <FormTextField
                    label={Translations.AddinServiceUrl}
                    name="serviceUrl"
                    control={control}
                    maxLength={255}
                    required={true}
                    rules={{ required: Translations.AddinServiceUrlMustBeDefined }}
                />
            </FormLine>
        </div>
    );
}

export default AppSettings;