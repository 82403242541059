import { CommandButton, IContextualMenuItem, IContextualMenuProps, Pivot, PivotItem } from "@fluentui/react";
import { callService, callServiceOnSave, hasPermission, navigateWithSetReturnUrl } from "../../models/common/appUtils";
import { getOwnerListItems, setDefaultOwnerCode } from "../../models/services/ownerService";
import { IOwnerListItem } from "../../models/owner";
import { authActions } from "../../store/auth";
import HomeView from "./HomeView";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { Translations } from "../../models/common/translations";
import TitleBar from "../common/TitleBar";
import { useSelector } from "react-redux";
import { pivotStyles } from "../appTheme";
import TemplateGroups from "../template/TemplateGroups";
import UseCases from "../useCase/useCases";
import { appActions } from "../../store/app";
import { AuthorizationLevel } from "../../models/common/enums";

//Styles
const HomeContainer = styled.div`
    width: 100%;
`

//Component
export const Home = () => {
    const [menuProps, setMenuProps] = useState<IContextualMenuProps>(null);
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [menuItems, setMenuItems] = useState<IContextualMenuItem[]>([])
    const { tab } = useParams();
    const [selectedKey, setSelectedKey] = useState<string>(tab || "0");

    useEffect(() => {
        setSelectedKey(tab || "0");
      }, [tab]);

    const handleLinkClick = (item) => {
        const newTab = item.props.itemKey;
        setSelectedKey(newTab);
    };

    const changeOwner = async (owner: IOwnerListItem) => {
        if (!owner) return;
        dispatch(authActions.setOwner(owner));
        await callServiceOnSave(() => setDefaultOwnerCode(owner.code), false);
    }

    const loadInitialData = async () => {
        if (!authUser.hasMultipleOwners) return;
        const newOwnerListItems = await callService(() => getOwnerListItems())
        if (!newOwnerListItems) return
        const newMenuProps: IContextualMenuProps =
        {
            items: newOwnerListItems.items.map(i => {
                return {
                    key: i.code,
                    text: i.name,
                    onClick: () => {
                        changeOwner(i);
                        return true;
                    }
                }
            })
        };
        setMenuProps(newMenuProps);
    }

    const toggleManagementMode = () => {
        dispatch(appActions.setManagementMode(!managementMode));
    }

    useEffect(() => {
        const items = [];

        if (hasPermission(authUser, AuthorizationLevel.User)) {
            items.push(
                {
                    key: "managementMode",
                    text: Translations.ManagementMode,
                    canCheck: true,
                    isChecked: managementMode,
                    onClick: () => {
                        toggleManagementMode();
                    }
                },
            );
        }

        items.push(
            {
                key: "settings",
                text: Translations.Settings,
                iconProps: { iconName: "Settings" },
                onClick: () => {
                    navigateWithSetReturnUrl(navigate, "/settings", "/");
                    return true;
                }
            },
        );
        setMenuItems(items)
    }, [managementMode]);


    useEffect(() => {
        loadInitialData();
    }, []);

    return (
        <HomeContainer>
            <TitleBar
                title={!managementMode ? Translations.Home : ""}
                menuItems={menuItems}
            >
                {!authUser.hasMultipleOwners &&
                    <span>{authUser.ownerName}</span>
                }
                {authUser.hasMultipleOwners &&
                    <CommandButton text={authUser.ownerName} menuProps={menuProps} />
                }
            </TitleBar>
            {managementMode ?
                <Pivot
                    selectedKey={selectedKey} 
                    onLinkClick={handleLinkClick}
                    styles={pivotStyles}
                >
                    <PivotItem headerText={Translations.Home} itemKey="0">
                        <HomeView/>
                    </PivotItem>
                    <PivotItem headerText={Translations.UseCases} itemKey="1">
                        <UseCases tabKey="1" />
                    </PivotItem>
                    <PivotItem headerText={Translations.Templates} itemKey="2">
                        <TemplateGroups tabKey="2"/>
                    </PivotItem>
                </Pivot>
                :
                <HomeView />
            }
        </HomeContainer>
    );
}

export default Home;
