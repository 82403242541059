import { IButtonProps, PrimaryButton, Spinner, SpinnerSize } from "@fluentui/react";
import * as React from "react";

export interface ISpinnerButtonProps {
  isSubmitting?: boolean;
}

export const SpinnerButton = (props: ISpinnerButtonProps & IButtonProps) => {
  return (
    <PrimaryButton {...props} disabled={props.isSubmitting}>
      {props.isSubmitting &&
        <Spinner size={SpinnerSize.small} />
      }
    </PrimaryButton>
  );
}

export default SpinnerButton;