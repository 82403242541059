import { DocumentCard, Stack } from "@fluentui/react";
import * as React from "react";
import { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { useCaseActions } from "../../store/useCase";
import { IUseCaseDataSetListItem } from "../../models/useCaseDataSet/useCaseDataSetListItem";
import UseCaseDataSetOpen from "./UseCaseDataSetOpen";
import { appTheme } from "../appTheme";

//Styles
const StyledDocumentCard = styled(DocumentCard)`
margin: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    
    &:hover {
        background-color: #e8e8e8;
    }
`

const StyledDocumentCardTitle = styled(Stack)`
    font-size: 14px;
    padding-bottom: 5px;
    align-items: center;
    
`

//Component
export interface IUseCaseImportDataProps {
    items: IUseCaseDataSetListItem[];
}
export const UseCaseImportData = (props: IUseCaseImportDataProps) => {
    const mounted = useRef(false);
    const dispatch = useAppDispatch();
    let { id } = useParams();
    const useCase = useSelector((state: RootState) => state.useCase.useCase);
    const [selectedItem, setSelectedItem] = React.useState<IUseCaseDataSetListItem>();

    const handleOpenUseCaseDataSet = async (useCaseDataSet: IUseCaseDataSetListItem) => {
        setSelectedItem(useCaseDataSet);
        dispatch(useCaseActions.setUseCaseDataSetOpen({ useCaseDataSet, generationParameters: useCase.generationParameters }));
    }

    const selectedStyle = React.useCallback((id) => { 
        return selectedItem?.id === id ? { 
            boxShadow: "0 0 2px 2px " + appTheme.semanticColors.primaryButtonBackground } : 
            null; 
        }, [id, selectedItem?.id]);

    useEffect(() => {
        mounted.current = true;
        return () => {
            mounted.current = false;
        };
    }, []);

    return (
        <div >
            {props.items.map(useCaseDataSet =>
                <StyledDocumentCard key={useCaseDataSet.id} onClick={() => handleOpenUseCaseDataSet(useCaseDataSet)} style={selectedStyle(useCaseDataSet.id)}>
                    <StyledDocumentCardTitle>{useCaseDataSet.name}</StyledDocumentCardTitle>
                </StyledDocumentCard>
            )}
            <UseCaseDataSetOpen />
        </div>
    );
}

export default UseCaseImportData;