export interface IUseCaseListItem {
    id: string;
    name: string;
    description: string;
    tip: string;
    image: string;
}

export class UseCaseListItem implements IUseCaseListItem {
    id: string;
    name: string;
    description: string;
    tip: string;
    image: string;

    constructor();
    constructor(obj: IUseCaseListItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.tip = obj && obj.tip || "";
        this.image = obj && obj.image || "";
    }
}