import { Stack } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import AppTooltip from "../common/AppTooltip";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { AccordionHeader, AccordionItem, AccordionPanel } from "@fluentui/react-accordion";
import { appTheme } from "../appTheme";
import { Translations } from "../../models/common/translations";

//Styles
const StyledAccordionItem = styled(AccordionItem)<{ isSelected?: boolean }>`
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    padding: 5px;
    overflow: hidden;
    margin-bottom: 10px;
    box-shadow: ${(props) => props.isSelected && `0 0 2px 2px ${appTheme.semanticColors.primaryButtonBackground}`};
    &:hover {
        background-color: #e8e8e8;
    }
`;

const StyledAccordionHeader = styled(AccordionHeader)`
    font-size: 20px;
    padding: 5px;
    align-items: center;
    
`

const StyledAccordionPanel = styled(AccordionPanel)`
    max-width: 100%;
    display: flex;
    padding: 5px;

`

const TemplateDiv = styled.div(
    {
        width: 131,
        height: 200,
        marginRight: 10,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
)

const Thumbnail = styled.div(
    {
        height: 185,
        alignItems: "center",

        img: {
            border: "1px solid #333",
            objectFit: "contain",
            width: "100%"
        },

        div: {
            textAlign: "center"
        }
    }
)

//Component
export interface IUseCaseAccordionItemProps {
    item: IUseCaseListItem;
    isSelected: boolean;
    onSelect: (id: string) => void;
    refreshList: () => void;
}

export const UseCaseAccordionItem = (props: IUseCaseAccordionItemProps) => {

    const handleSelect = () => {
        props.onSelect(props.item.id);
    };

    return (
        <StyledAccordionItem value={props.item.id} isSelected={props.isSelected}>
            <AppTooltip
                text={props.item.tip}
            >
                <StyledAccordionHeader onClick={handleSelect} expandIconPosition="end">
                    <div>{props.item.name}</div>
                </StyledAccordionHeader>
                {(props.item.description || props.item.image) &&
                <StyledAccordionPanel>
                    {props.item.image &&
                        <TemplateDiv>
                            <Thumbnail>
                                <img src={"data:image/jpg;base64," + props.item.image} alt={Translations.Thumbnail} ></img>
                            </Thumbnail>
                        </TemplateDiv>
                    }
                    {props.item.description &&
                        <Stack style={{ flex: 1 }}>
                            <div>{props.item.description}</div>
                        </Stack>
                    }
                </StyledAccordionPanel>
                }
            </AppTooltip>
        </StyledAccordionItem>
    );
}

export default UseCaseAccordionItem;
