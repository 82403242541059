import { IUseCaseEditItem, UseCaseEditItem } from ".";
import { Base } from "../../framework/base";
import { IUseCaseDataListItem, UseCaseDataListItem } from "../useCaseData/useCaseDataListItem";

export interface IUseCaseEdit {
    item: IUseCaseEditItem;
    useCaseDatas: IUseCaseDataListItem[];
}

export class UseCaseEdit implements IUseCaseEdit {
    item: UseCaseEditItem;
    useCaseDatas: UseCaseDataListItem[];

    constructor();
    constructor(obj: IUseCaseEdit);
    constructor(obj?: any) {
        this.item = new UseCaseEditItem(obj?.item);
        this.useCaseDatas = Base.getTypedArray(obj ? obj.useCaseDatas : null, UseCaseDataListItem);
    }
}
