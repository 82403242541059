import { Base } from "../../framework/base";
import { ITemplateGroup, TemplateGroup } from "./templateGroup";

export interface ITemplateGroups {
    items: ITemplateGroup[];
}

export class TemplateGroups implements ITemplateGroups {
    items: TemplateGroup[];

    constructor();
    constructor(obj: ITemplateGroups);
    constructor(obj?: any) {
        this.items = Base.getTypedArray(obj ? obj.items : null, TemplateGroup);
    }
}