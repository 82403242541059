export interface ITemplateThumbnailResult {
    thumbnail: string;
}

export class TemplateThumbnailResult implements ITemplateThumbnailResult {
    thumbnail: string;

    constructor();
    constructor(obj: ITemplateThumbnailResult);
    constructor(obj?: any) {
        this.thumbnail = obj && obj.thumbnail || "";
    }
}