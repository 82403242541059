import { Base } from "../../framework/base";
import { generationParameterAllowMultipleKey, generationParameterAutoOpenTaskPaneKey, generationParameterContextKey, generationParameterOmitElementsWithPrefixKey, generationParameterRootElementNameKey, generationParameterTemplateGroupKey, generationParameterUseCollectionElementsKey } from "../common/consts";
import { INameValue, NameValue } from "../common/nameValue";

export interface IUseCaseGenerationParameters {
    context: string;
    templateGroup: string;
    rootElementName: string;
    omitElementsWithPrefix: string;
    useCollectionElements: boolean;
    allowMultiple: boolean;
    autoOpenTaskPane: boolean;
}

export class UseCaseGenerationParameters implements IUseCaseGenerationParameters {
    context: string;
    templateGroup: string;
    rootElementName: string;
    omitElementsWithPrefix: string;
    useCollectionElements: boolean;
    allowMultiple: boolean;
    autoOpenTaskPane: boolean;

    constructor();
    constructor(obj: INameValue[]);
    constructor(obj?: any) {
        this.context = NameValue.getValue(obj, generationParameterContextKey, "");
        this.templateGroup = NameValue.getValue(obj, generationParameterTemplateGroupKey, "");
        this.rootElementName = NameValue.getValue(obj, generationParameterRootElementNameKey, "");
        this.omitElementsWithPrefix = NameValue.getValue(obj, generationParameterOmitElementsWithPrefixKey, "");
        this.useCollectionElements = Base.strToBool(NameValue.getValue(obj, generationParameterUseCollectionElementsKey, ""));
        this.allowMultiple = Base.strToBool(NameValue.getValue(obj, generationParameterAllowMultipleKey, ""));
        this.autoOpenTaskPane = Base.strToBool(NameValue.getValue(obj, generationParameterAutoOpenTaskPaneKey, ""));
    }
}
