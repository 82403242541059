export interface ITemplateEditItemCc {
    id: string;
    tag: string;
    type: number;
}

export class TemplateEditItemCc implements ITemplateEditItemCc {
    id: string;
    tag: string;
    type: number;

    constructor();
    constructor(obj: ITemplateEditItemCc);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.tag = obj && obj.tag || "";
        this.type = obj && obj.type || 0;
    }
}
