import { DocumentCard, Image, ImageFit, Stack } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import AppTooltip from "../common/AppTooltip";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { useCaseActions } from "../../store/useCase";
import { appTheme } from "../appTheme";


//Styles
const StyledDocumentCard = styled(DocumentCard)`
margin: 5px;
    margin-bottom: 10px;
    cursor: pointer;
    
    &:hover {
        background-color: #e8e8e8;
    }
`

const StyledDocumentCardContent = styled.div`
    padding: 5px;
`

const StyledImage = styled(Image)`
    margin-right: 5px;
    
`
const StyledDocumentCardTitle = styled(Stack)`
    font-size: 20px;
    padding-bottom: 5px;
    align-items: center;
    
`

//Component
export interface IUseCasesListItemProps {
    item: IUseCaseListItem;
}

export const UseCasesListItem = (props: IUseCasesListItemProps) => {
    const dispatch = useAppDispatch();

    const selectedUseCase = useSelector((state: RootState) => state.useCase.selectedUseCase);
    const selectedStyle = React.useCallback(() => { 
        return selectedUseCase?.id === props.item?.id ? { 
            boxShadow: "0 0 2px 2px " + appTheme.semanticColors.primaryButtonBackground } : 
            null; 
        }, [props.item?.id, selectedUseCase?.id]);

    return (
        <StyledDocumentCard onClick={()=>dispatch(useCaseActions.setSelectedUseCase(props.item))} style={selectedStyle()}>
            <AppTooltip
                text={props.item.tip}
            >
                <StyledDocumentCardContent>
                    <StyledDocumentCardTitle horizontal>
                        {!!props.item.image &&
                            <StyledImage
                                src={"data:image/jpg;base64," + props.item.image} 
                                height={50}
                                width={50}
                                imageFit={ImageFit.cover}
                            />
                        }
                        <div>{props.item.name}</div>
                    </StyledDocumentCardTitle>
                </StyledDocumentCardContent>
            </AppTooltip>
        </StyledDocumentCard>
    );
}

export default UseCasesListItem;
