import { ITemplateEditItem, TemplateEditItem } from "./templateEditItem";

export interface ITemplateEdit {
    item: ITemplateEditItem;
}

export class TemplateEdit implements ITemplateEdit {
    item: TemplateEditItem;

    constructor();
    constructor(obj: ITemplateEdit);
    constructor(obj?: any) {
        this.item = new TemplateEditItem(obj?.item);
    }
}
