import { Base } from "../../framework/base";
import { AuthorizationLevel } from "../common/enums";

export interface IOwnerListItem {
    code: string;
    name: string;
    authorizationLevel: AuthorizationLevel;
}

export class OwnerListItem implements IOwnerListItem {
    code: string;
    name: string;
    authorizationLevel: AuthorizationLevel;

    constructor();
    constructor(obj: IOwnerListItem);
    constructor(obj?: any) {
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.authorizationLevel = AuthorizationLevel.Reader;
        if (obj && !Base.isNullOrUndefined(obj.authorizationLevel)) {
            this.authorizationLevel = obj.authorizationLevel;
        }
    }
}