import { Base } from "../../framework/base";
import { TemplateFormat, TemplateStateCode, TemplateTypeClass } from "../common/enums";
import { ITemplateEditItemCc, TemplateEditItemCc } from "./templateEditItemCc";

export interface ITemplateEditItem {
    id: string;
    code: string;
    name: string;
    stateCode: TemplateStateCode;
    stateName: string;
    comment: string;
    image: string;
    templateFormat: TemplateFormat;
    templateTypeClass: TemplateTypeClass;
    templateFileId: string;
    templateFileName: string;
    templateFileRowId: string;
    templateResourceLangCode: string;
    templateResourceLangName: string;
    templateResourceOutputTypeCode: string;
    templateResourceOutputTypeName: string;
    placeHolders: ITemplateEditItemCc[];
    dataControls: ITemplateEditItemCc[];
    //Relation
    relationId: string;
    relationTag: string;
    relationCondition: string;
    relationAction: string;
    relationSection: string;
    //Children
    children: ITemplateEditItem[];
    //UI
    level: number;

    getAsList(level: number): ITemplateEditItem[];
    findById(id: string): ITemplateEditItem;
}

export class TemplateEditItem implements ITemplateEditItem {
    id: string;
    code: string;
    name: string;
    stateCode: TemplateStateCode;
    stateName: string;
    comment: string;
    image: string;
    templateFormat: TemplateFormat;
    templateTypeClass: TemplateTypeClass;
    templateFileId: string;
    templateFileName: string;
    templateFileRowId: string;
    templateResourceLangCode: string;
    templateResourceLangName: string;
    templateResourceOutputTypeCode: string;
    templateResourceOutputTypeName: string;
    placeHolders: TemplateEditItemCc[];
    dataControls: TemplateEditItemCc[];
    //Relation
    relationId: string;
    relationTag: string;
    relationCondition: string;
    relationAction: string;
    relationSection: string;    
    //Children
    children: TemplateEditItem[];
    //UI
    level: number;

    constructor();
    constructor(obj: ITemplateEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.stateCode = obj && obj.stateCode || TemplateStateCode.Created;
        this.stateName = obj && obj.stateName || "";
        this.comment = obj && obj.comment || "";
        this.image = obj && obj.image || "";
        this.templateFormat = obj && obj.templateFormat || TemplateFormat.Docx;
        this.templateTypeClass = obj && obj.templateTypeClass || TemplateTypeClass.DocumentModel;
        this.templateFileId = obj && obj.templateFileId || "";
        this.templateFileName = obj && obj.templateFileName || "";
        this.templateFileRowId = obj && obj.templateFileRowId || "";
        this.templateResourceLangCode = obj && obj.templateResourceLangCode || "";
        this.templateResourceLangName = obj && obj.templateResourceLangName || "";
        this.templateResourceOutputTypeCode = obj && obj.templateResourceOutputTypeCode || "";
        this.templateResourceOutputTypeName = obj && obj.templateResourceOutputTypeName || "";
        this.placeHolders = Base.getTypedArray(obj ? obj.placeHolders : null, TemplateEditItemCc);
        this.dataControls = Base.getTypedArray(obj ? obj.dataControls : null, TemplateEditItemCc);
        //Relation
        this.relationId = obj && obj.relationId || "";
        this.relationTag = obj && obj.relationTag || "";
        this.relationCondition = obj && obj.relationCondition || "";
        this.relationAction = obj && obj.relationAction || "";
        this.relationSection = obj && obj.relationSection || "";        
        //Children
        this.children = Base.getTypedArray(obj ? obj.children : null, TemplateEditItem);
        //UI
        this.level = obj && obj.level || 0;        
    }

    getAsList(level: number = 0): ITemplateEditItem[] {
        let result: ITemplateEditItem[] = [];
        result.push({ ...this, level: level});
        for (const child of this.children) {
            result = result.concat(child.getAsList(level + 1));
        }
        return result;
    }

    findById(id: string): ITemplateEditItem | null {
        if (this.id === id) return this;
        for (const child of this.children) {
            const result = child.findById(id);
            if (!result) continue;
            return result;
        }
        return null;
    }

}
