import * as React from "react";
import styled from "@emotion/styled";
import { ITemplateGroup } from "../../models/templateGroup/templateGroup";
import { Translations } from "../../models/common/translations";
import UseCaseTemplateListItem from "./UseCaseTemplateListItem";
import { CommandButton, ContextualMenu, IContextualMenuItem, IContextualMenuProps, IconButton, Stack } from "@fluentui/react";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { templateActions } from "../../store/template";
import PageSubTitle from "../common/PageSubTitle";
import { useEffect, useState } from "react";

//Styles
const TemplateGroup = styled.div(
    {
        width: "100%",
    }
)

const Templates = styled.div(
    {
        width: "100%",
        display: "flex",
        alignItems: "stretch",
        flexFlow: "row wrap",
        cursor: "pointer",
    }
)

const TitleRow = styled.div`
    display: flex;
`

//Component
export interface IUseCaseTemplateGroupsListProps {
    useCaseId: string;
    templateGroups: ITemplateGroup[];
}

export const UseCaseTemplateGroupsList = (props: IUseCaseTemplateGroupsListProps) => {
    const buttonRef = React.useRef(null);
    const dispatch = useAppDispatch();
    const [menuItems, setMenuItems] = React.useState<IContextualMenuItem[]>([]);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const showArchived = useSelector((state: RootState) => state.template.showArchived);
    const [menuProps, setMenuProps] = useState<IContextualMenuProps>(null);
    const [selectedGroup, setSelectedGroup] = useState<ITemplateGroup[]>([])

    const handleShowHideArchived = () => {
        dispatch(templateActions.setShowArchived(!showArchived));
    }

    const handleMore = () => {
        setMenuItems([
            { key: "showHideArchived", iconProps: { iconName: showArchived ? "Hide" : "RedEye" }, text: showArchived ? Translations.HideHidden : Translations.ShowHidden, onClick: handleShowHideArchived }
        ])
    }

    const onHideContextMenu = React.useCallback(() => setMenuItems([]), []);

    const loadInitialData = async () => {
        const newTemplateGroupListItems = props.templateGroups
        setSelectedGroup(newTemplateGroupListItems)
 
        const newMenuProps: IContextualMenuProps =
        {
            items: [{
                key: 'customItem',
                text: Translations.All,
                onClick: () => {
                    changeGroup(props.templateGroups);
                    return true;
                }
            },
            ...newTemplateGroupListItems.map(i => {
                return {
                    key: i.code,
                    text: i.name,
                    onClick: () => {
                        changeGroup([i]);
                        return true;
                    }
                }
            })]
        };
        setMenuProps(newMenuProps);
    }

    const changeGroup = async (groups: ITemplateGroup[]) => {
        if (!groups) { setSelectedGroup(props.templateGroups) };
        setSelectedGroup(groups)
        if (groups && groups[0].templates) {
            dispatch(templateActions.setTemplate(groups[0].templates[0]));
        }
    }

    useEffect(() => {
        loadInitialData();
    }, [props.templateGroups]);

    return (
        <React.Fragment>
            <PageSubTitle
                text={Translations.SelectTemplate}
                suffix={managementMode && !props.useCaseId ?
                    <Stack horizontal>
                        <div>
                            <CommandButton text={Translations.TemplateGroup} menuProps={menuProps} />
                        </div>
                        <div
                            onClick={(e) => e.stopPropagation()}
                            ref={buttonRef}><IconButton
                                iconProps={{ iconName: "More" }}
                                title={Translations.More}
                                ariaLabel={Translations.More}
                                onClick={handleMore} />
                        </div>
                    </Stack> : null}
            />
            <div style={{ overflowY: "auto", overflowX: "hidden" }}>
                {selectedGroup.map((templateGroup) =>
                    <TemplateGroup key={templateGroup.code}>
                        <h4>{templateGroup.name}</h4>
                        <Templates>
                            {templateGroup.templates.map((template) =>
                                <UseCaseTemplateListItem
                                    key={template.code}
                                    useCaseId={props.useCaseId}
                                    template={template}
                                />
                            )}
                        </Templates>
                    </TemplateGroup>
                )}
            </div>
            {managementMode &&
                <ContextualMenu
                    items={menuItems}
                    hidden={menuItems.length < 1}
                    target={buttonRef}
                    onItemClick={onHideContextMenu}
                    onDismiss={onHideContextMenu}
                />
            }
        </React.Fragment>
    );
}

export default UseCaseTemplateGroupsList;