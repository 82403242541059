import { PrimaryButton } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TitleBar from "../common/TitleBar";
import { Translations } from "../../models/common/translations";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { getUseCaseBase64Content, getUseCaseListItems } from "../../models/services/useCaseService";
import { callService, useCaseCanBeAdded } from "../../models/common/appUtils";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState } from "../../store";
import { Base } from "../../framework/base";
import { UseCaseAccordionItem } from "./UseCaseAccordionItem";
import { Accordion } from "@fluentui/react-accordion";
import { ExcelHelper } from "../../models/excel/excelHelper";

//Styles
const GuidTextDiv = styled.div`
    padding-bottom: 10px;
`

//Component
export const UseCaseList = () => {
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [useCaseListItems, setUseCaseListItems] = useState<IUseCaseListItem[]>([]);
    const [selectedItemId, setSelectedItemId] = useState<string>();

    const loadInitialData = async () => {
        const newUseCaseListItems = await callService(() => getUseCaseListItems())
        if (!newUseCaseListItems) return
        setUseCaseListItems(newUseCaseListItems.items);
    }

    useEffect(() => {
        loadInitialData();
    }, [authUser?.ownerCode]);

    const refreshList = async () => {
        await loadInitialData();
    }

    const handleAddUseCase = () => {
        navigate("/usecaseedit/" + Base.emptyGuid);
    }

    const handleItemSelect = (id: string) => {
        setSelectedItemId(id);
    };

    const handleOpenUseCaseExcel = async () => {
        if (!selectedItemId) return;
        const content = await callService(() => getUseCaseBase64Content(selectedItemId), false)
        if (!content) return;
        await ExcelHelper.createNewWorkbook(content);
    }

    return (
        <div>
            <TitleBar
                title={Translations.UseCases}
                menuItems={[
                    {
                        key: "refresh",
                        text: Translations.RefreshList,
                        iconProps: { iconName: "Refresh" },
                        onClick: () => {
                            refreshList();
                            return true;
                        }
                    }
                ].concat(useCaseCanBeAdded(authUser) && managementMode
                    ? [
                        {
                            key: "add",
                            text: Translations.AddUseCase,
                            iconProps: { iconName: "Add" },
                            onClick: () => {
                                handleAddUseCase();
                                return true;
                            }
                        }
                    ]
                    : [])}
            >
            </TitleBar>
            <div>
                <GuidTextDiv>{Translations.OpenSampleDataFile}</GuidTextDiv>
                <div>
                    <Accordion collapsible>
                        {useCaseListItems.map((item) =>
                            <UseCaseAccordionItem
                                key={item.id}
                                item={item}
                                isSelected={item.id === selectedItemId}
                                onSelect={handleItemSelect}
                                refreshList={refreshList}
                            />
                        )}
                    </Accordion>
                </div>
                <PrimaryButton disabled={!selectedItemId} iconProps={{ iconName: "Add" }} text={Translations.NewDocument} onClick={handleOpenUseCaseExcel} />
            </div>
        </div>
    );
}

export default UseCaseList;