export interface IParameterData {
    id: string;
    data: string;
    error: string;
    errorData: string;
    filename: string;
}

export class ParameterData implements IParameterData {
    id: string;
    data: string;
    error: string;
    errorData: string;
    filename: string;

    constructor();
    constructor(obj: IParameterData);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.data = obj && obj.data || "";
        this.error = obj && obj.error || "";
        this.errorData = obj && obj.errorData || "";
        this.filename = obj && obj.filename || "";
    }
}