import { RJSFSchema, UiSchema } from "@rjsf/utils";

export interface IUseCaseDataSetEditItem {
    id: string;
    name: string;
    description: string;
    schema: RJSFSchema;
    uiSchema: UiSchema;
}

export class UseCaseDataSetEditItem implements IUseCaseDataSetEditItem {
    id: string;
    name: string;
    description: string;
    schema: RJSFSchema;
    uiSchema: UiSchema;

    constructor();
    constructor(obj: IUseCaseDataSetEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.schema = null;
        if (obj && obj.schema) {
            this.schema = JSON.parse(obj.schema);
        }
        console.log("this.schema", this.schema);
        this.uiSchema = {};
        if (obj && obj.uiSchema) {
            this.uiSchema = JSON.parse(obj.uiSchema);
        }
    }
}
