/* global Excel, Office, OfficeExtension */
import { Base } from "../../framework/base";
import { INameValue } from "../common/nameValue";
import { Translations } from "../common/translations";
import { IUseCaseDataSetTableData } from "../useCaseDataSet/useCaseDataSetTableData";

export class NamedRange {
    name: string;
    sheetName: string;
    range: Excel.Range;
}

export class NamedValue {
    name: string;
    value: string;
    base64Image: boolean;

    static CreateNamedValue(name: string, value: string, base64Image: boolean = false): NamedValue {
        const result = new NamedValue();
        result.name = name;
        result.value = value;
        result.base64Image = base64Image;
        return result;
    }
}

export class NamedShape {
    shape: Excel.Shape;
    image: OfficeExtension.ClientResult<string>;
}

export class NamedTable {
    table: Excel.Table;
    sheetName: string;
}

export class NamedChart {
    chart: Excel.Chart;
    image: OfficeExtension.ClientResult<string>;
}

interface CreateTableResult {
    error: string;
    namedTable: NamedTable;
    sheet: Excel.Worksheet;
}

export class ExcelHelper {
    static loadRange(sheet: Excel.Worksheet, rangeName: string): Excel.Range {
        const range = sheet.getRange(rangeName);
        range.load("text");
        range.load("cellCount");
        range.load("address");
        return range;
    }

    static getCellRangeName(cellRange: Excel.Range, oneCellRanges: NamedRange[]): string {
        if (oneCellRanges.length) {
            const oneCellRange = oneCellRanges.find(i => i.range.address === cellRange.address);
            if (oneCellRange) {
                return oneCellRange.name;
            }
        }
        return null;
    }

    static async createNewWorkbook(base64content: string = null) {
        try {
            return await Excel.run(async (context) => {
                Excel.createWorkbook(base64content);
                return context.sync();
            });
        } catch (error) {
            console.error("ERROR getAllowedTemplateGroupCodes: ", error);
            return [];
        }
    }

    static async getAllNamedRanges(omitElementsWithPrefix: string, context: Excel.RequestContext, sheet: Excel.Worksheet, namedItems: Excel.NamedItem[]): Promise<NamedRange[]> {
        const result: NamedRange[] = [];
        for (const namedItem of namedItems) {
            if (namedItem.name.indexOf("_xlfn.") === 0) continue;
            if (omitElementsWithPrefix && namedItem.name.indexOf(omitElementsWithPrefix) === 0) continue;
            if (namedItem.value.indexOf(sheet.name) !== 0) continue;
            const range = namedItem.type !== Excel.NamedItemType.error
                ? this.loadRange(sheet, namedItem.name)
                : null;
            const namedRange = new NamedRange();
            namedRange.name = namedItem.name;
            namedRange.sheetName = sheet.name;
            namedRange.range = range;
            result.push(namedRange);
        }
        await context.sync();
        return result;
    }    

    static getNamedTable(sheet: Excel.Worksheet, table: Excel.Table): NamedTable {
        table.load("name");
        table.load("rows");
        table.load("columns");
        const result = new NamedTable();
        result.sheetName = sheet.name;
        result.table = table;
        return result;
    }

    static async getAllTables(omitElementsWithPrefix: string, context: Excel.RequestContext, sheet: Excel.Worksheet): Promise<NamedTable[]> {
        let result: NamedTable[] = [];
        for (const table of sheet.tables.items) {
            // table.load("name");
            // table.load("rows");
            // table.load("columns");
            // const namedTable = new NamedTable();
            // namedTable.sheetName = sheet.name;
            // namedTable.table = table;
            // result.push(namedTable);
            result.push(this.getNamedTable(sheet, table));
        }
        await context.sync();
        if (omitElementsWithPrefix) {
            result = result.filter(i => i.table?.name && i.table.name.indexOf(omitElementsWithPrefix) !== 0);
        }
        return result;
    }

    static async getAllShapes(omitElementsWithPrefix: string, context: Excel.RequestContext, sheet: Excel.Worksheet): Promise<NamedValue[]> {
        let result: NamedShape[] = [];
        for (const shape of sheet.shapes.items) {
            shape.load("name");
            const namedShape = new NamedShape();
            namedShape.shape = shape;
            namedShape.image = shape.getAsImage(Excel.PictureFormat.png);
            result.push(namedShape);
        }
        await context.sync();
        if (omitElementsWithPrefix) {
            result = result.filter(i => i.shape?.name && i.shape.name.indexOf(omitElementsWithPrefix) !== 0);
        }
        return result.map(i => NamedValue.CreateNamedValue(i.shape.name, i.image.value, true));
    }

    static async getAllCharts(omitElementsWithPrefix: string, context: Excel.RequestContext, sheet: Excel.Worksheet): Promise<NamedValue[]> {
        let result: NamedChart[] = [];
        for (const chart of sheet.charts.items) {
            chart.load("name");
            const namedChart = new NamedChart();
            namedChart.chart = chart;
            namedChart.image = chart.getImage();
            result.push(namedChart);
        }
        await context.sync();
        if (omitElementsWithPrefix) {
            result = result.filter(i => i.chart?.name && i.chart.name.indexOf(omitElementsWithPrefix) !== 0);
        }
        return result.map(i => NamedValue.CreateNamedValue(i.chart.name, i.image.value, true));
    }

    // #region CustomProperty
    static loadCustomProperty(workbook: Excel.Workbook, customPropertyName: string): Excel.CustomProperty {
        const result = workbook.properties.custom.getItemOrNullObject(customPropertyName);
        result.load("isNullObject");
        result.load("key");
        result.load("type");
        result.load("value");
        return result;
    }

    static getCustomPropertyStringValue(customProperty: Excel.CustomProperty, defaultValue: string = ""): string {
        if (!customProperty.isNullObject && customProperty.value) {
            if (customProperty.type === "String") {
                const value: string = customProperty.value as string;
                return value.trim() ?? defaultValue;
            }
        }
        return defaultValue;
    }

    static getCustomPropertyBooleanValue(customProperty: Excel.CustomProperty, defaultValue: boolean = false): boolean {
        if (!customProperty.isNullObject) {
            if (customProperty.type === "Boolean") {
                return customProperty.value as boolean;
            }
        }
        return defaultValue;
    }

    static getCustomPropertyStringArrayValue(customProperty: Excel.CustomProperty, defaultValue: string[] = []): string[] {
        if (!customProperty.isNullObject && customProperty.value) {
            if (customProperty.type === "String") {
                const value: string = customProperty.value as string;
                return value.split(",").map(i => i.trim());
            }
        }
        return defaultValue;
    }

    static async getStringCustomProperty(customPropertyName: string, defaultValue: string = ""): Promise<string> {
        if (!customPropertyName) return defaultValue;
        try {
            return await Excel.run(async (context) => {
                const templateGroupProperty = ExcelHelper.loadCustomProperty(context.workbook, customPropertyName);
                await context.sync();
                return ExcelHelper.getCustomPropertyStringValue(templateGroupProperty, defaultValue);
            });
        } catch (error) {
            console.error("ERROR getStringCustomProperty (" + customPropertyName + "): ", error);
            return defaultValue;
        }
    }

    static async getStringArrayCustomProperty(customPropertyName: string, defaultValue: string[] = []): Promise<string[]> {
        if (!customPropertyName) return defaultValue;
        try {
            return await Excel.run(async (context) => {
                const templateGroupProperty = ExcelHelper.loadCustomProperty(context.workbook, customPropertyName);
                await context.sync();
                return ExcelHelper.getCustomPropertyStringArrayValue(templateGroupProperty, defaultValue);
            });
        } catch (error) {
            console.error("ERROR getStringArrayCustomProperty (" + customPropertyName + "): ", error);
            return defaultValue;
        }
    }

    static async getCustomProperties(customPropertyNames: string[]): Promise<INameValue[]> {
        if (!customPropertyNames || customPropertyNames.length < 1) return [];
        try {
            return await Excel.run(async (context) => {
                const customProperties: Excel.CustomProperty[] = [];
                for (const customPropertyName of customPropertyNames) {
                    customProperties.push(ExcelHelper.loadCustomProperty(context.workbook, customPropertyName));
                }
                await context.sync();
                //console.log("customProperties", customProperties)
                const result: INameValue[] = [];
                for (let i = 0; i < customPropertyNames.length; i++) {
                    const customProperty = customProperties[i];
                    const value = !customProperty.isNullObject && customProperty.value
                        ? customProperty.value
                        : null;
                    result.push({ name: customPropertyNames[i], value: value });
                }
                return result;
            });
        } catch (error) {
            console.error("ERROR getCustomProperties (" + JSON.stringify(customPropertyNames) + "): ", error);
            return [];
        }
    }

    static async setCustomProperties(newValues: INameValue[]): Promise<void> {
        if (!newValues || newValues.length < 1) return;
        try {
            await Excel.run(async (context) => {
                const customDocProperties = context.workbook.properties.custom;
                for (const newValue of newValues) {
                    customDocProperties.add(newValue.name, newValue.value);
                }
                await context.sync();
            });
        } catch (error) {
            console.error("ERROR setCustomProperties (" + JSON.stringify(newValues) + "): ", error);
        }
    }
    // #endregion CustomProperty

    // #region AutoOpen
    static getDocumentSettingBooleanValue(settingName: string): boolean {
        return !!Office.context.document.settings.get(settingName);
    }
    
    static async setDocumentSettingBooleanValue(settingName: string, enabled: boolean, save: boolean = true): Promise<void> {
        Office.context.document.settings.set(settingName, enabled);
        if (!save) return;
        await Office.context.document.settings.saveAsync();
    }

    static async removeDocumentSettingBooleanValue(settingName: string, save: boolean = true): Promise<void> {
        Office.context.document.settings.remove(settingName);
        if (!save) return;
        await Office.context.document.settings.saveAsync();
    }

    static async saveDocumentSettings(): Promise<void> {
        await Office.context.document.settings.saveAsync();
    }
    // #endregion AutoOpen

    // #region Set data to tables
    //5 => F
    private static GetColAddress(colIndex: number): string {
        let result = "";
        let index = colIndex;
        do
        {
            const div = Math.floor(index / 26);
            const mod = index % 26;
            result = result + String.fromCharCode(64 + (div == 0 ? mod + 1 : div));
            if (div > 0 && mod == 0)
            {
                result = result + String.fromCharCode(64 + mod + 1);
            }
            const sub = div == 0 ? mod : div * 26;
            if (index <= sub) break;
            index = index - sub;
            /* eslint-disable no-constant-condition */
        } while (true);
        /* eslint-enable no-constant-condition */
        return result;
    }

    private static async createTableBaseOnTableData(context: Excel.RequestContext, sheets: Excel.WorksheetCollection, tableData: IUseCaseDataSetTableData): Promise<CreateTableResult> {
        const result: CreateTableResult = {
            error: null,
            namedTable: null,
            sheet: null
        };
        //Get header columns
        let headerColumnNames: string[] = [];
        if (tableData.data.length) {
            headerColumnNames = Base.getPropertyNames(tableData.data[0]);
        }
        if (headerColumnNames.length < 1) {
            result.error = Translations.TableDoesNotHaveDataCannotCreateTable + " (" + tableData.name + ")";
            return result; 
        }
        //Create new sheet
        let newSheetName = tableData.name;
        let index = 1;
        while (sheets.items.find(i => i.name === newSheetName)) {
            newSheetName = tableData.name + "_" + index.toString(10);
            index++;
        }
        let sheet = sheets.add(newSheetName);
        sheet.load("name");
        await context.sync();        
        //Create new table on new sheet
        const address = this.GetColAddress(0) + "1:" + this.GetColAddress(headerColumnNames.length > 0 ? headerColumnNames.length - 1 : 0) + "1";
        let table = sheet.tables.add(address, true);
        table.name = tableData.name;
        table.getHeaderRowRange().values = [headerColumnNames.length ? headerColumnNames : []];
        result.namedTable = this.getNamedTable(sheet, table);
        result.sheet = sheet;
        return result;
    }

    static async setDataToTables(omitElementsWithPrefix: string, items: IUseCaseDataSetTableData[], createTablesIfMissing: boolean): Promise<string> {
        const errors: string[] = [];
        try {
            await Excel.run(async (context) => {
                const workbook = context.workbook;
                const worksheets = workbook.worksheets;
                workbook.load("names")
                worksheets.load("items")
                await context.sync();
                for (let i = 0; i < worksheets.items.length; i++) {
                    const sheet = worksheets.items[i];
                    sheet.load("name");
                    sheet.load("tables");
                }
                await context.sync();
                let allRanges: NamedRange[] = [];
                let allTables: NamedTable[] = [];
                let newSheets: Excel.Worksheet[] = [];
                for (let i = 0; i < workbook.worksheets.items.length; i++) {
                    const sheet = workbook.worksheets.items[i];
                    allRanges = allRanges.concat(await this.getAllNamedRanges(omitElementsWithPrefix, context, sheet, workbook.names.items));
                    allTables = allTables.concat(await this.getAllTables(omitElementsWithPrefix, context, sheet));
                }
                const oneCellRanges = allRanges.filter(i => i.range && i.range.cellCount === 1);
                //Create missing tables
                if (createTablesIfMissing) {
                    for (const item of items) {
                        const table = allTables.find(i => i.table.name === item.name);
                        if (table) continue;
                        const crateTableResult = await this.createTableBaseOnTableData(context, worksheets, item);
                        if (!crateTableResult.namedTable) {
                            errors.push(crateTableResult.error);
                            continue;
                        }
                        allTables.push(crateTableResult.namedTable);
                        newSheets.push(crateTableResult.sheet);
                    }
                    await context.sync();
                }
                for (const item of items) {
                    const table = allTables.find(i => i.table.name === item.name);
                    if (!table) {
                        errors.push(Translations.TableNotFound + " (" + item.name + ")");
                        continue;
                    }
                    const headerRange = table.table.getHeaderRowRange();
                    headerRange.load("text");
                    headerRange.load("rowCount");
                    headerRange.load("columnCount");
                    const bodyRange = table.table.getDataBodyRange();
                    bodyRange.load("text");
                    await context.sync();
                    //Load cell range data
                    const headerValues = headerRange.text;
                    const cellRanges: Excel.Range[][] = [];
                    const rowCellRanges: Excel.Range[] = [];
                    for (let colIndex = 0; colIndex < headerValues[0].length; colIndex++) {
                        const cell = headerRange.getCell(0, colIndex)
                        cell.load("address");
                        rowCellRanges.push(cell);
                    }
                    cellRanges.push(rowCellRanges);
                    await context.sync();
                    //Get header column names
                    const columnTitles: string[] = [];
                    for (let colIndex = 0; colIndex < headerValues[0].length; colIndex++) {
                        let columnTitle = headerValues[0][colIndex];
                        const cellRangeName = this.getCellRangeName(cellRanges[0][colIndex], oneCellRanges);
                        if (cellRangeName) {
                            columnTitle = cellRangeName;
                        }
                        columnTitles.push(columnTitle);
                    }
                    //Remove old rows from table
                    for (let rowIndex = table.table.rows.count - 1; rowIndex >= 0; rowIndex--) {
                        table.table.rows.items[rowIndex].delete();
                    }
                    //Add new rows to table
                    const rowsData: Array<Array<boolean | string | number>> = [];
                    for (const dataRow of item.data) {
                        const rowData: Array<boolean | string | number> = [];
                        for (const columnTitle of columnTitles) {
                            const colData = dataRow[columnTitle];
                            rowData.push(colData != undefined ? colData : null);
                        }
                        rowsData.push(rowData);
                    }
                    if (rowsData.length < 1) {
                        errors.push(Translations.DataSetDoesNotContainData + " (" + Translations.Table + ": " + item.name + ")");
                        continue;
                    }
                    table.table.rows.add(null, rowsData);
                    //Autofit columns of created tables
                    if (newSheets.length && Office.context.requirements.isSetSupported("ExcelApi", "1.2")) {
                        for (const newSheet of newSheets) {
                            newSheet.getUsedRange().format.autofitColumns();
                            newSheet.getUsedRange().format.autofitRows();
                        }
                    }
                    await context.sync();
                }
            });
        } catch (error) {
            console.error("ERROR getParameterDatas: ", error);
            errors.push(error.toString());
        }
        return errors.join(", ");
    }
    // #endregion Set data to tables
    
    // #region Get file content
    private static onGotAllSlices(docDataSlices: any[], callBack: (bytes: Uint8Array, error: string) => void) {
        let docData = [];
        docDataSlices.forEach((slice) => {
            docData = docData.concat(slice);
        });
        callBack(new Uint8Array(docData), null);
    }

    private static getSliceAsync(file: Office.File, nextSlice: number, sliceCount: number, gotAllSlices: boolean, docDataSlices: any[], slicesReceived: number, callBack: (bytes: Uint8Array, error: string) => void) {
        file.getSliceAsync(nextSlice, function (result) {
            if (result.status == Office.AsyncResultStatus.Succeeded) {
                if (!gotAllSlices) { /* Failed to get all slices, no need to continue. */
                    return;
                }
                // Got one slice, store it in a temporary array.
                // (Or you can do something else, such as
                // send it to a third-party server.)
                docDataSlices[result.value.index] = result.value.data;
                if (++slicesReceived == sliceCount) {
                    // All slices have been received.
                    file.closeAsync();
                    ExcelHelper.onGotAllSlices(docDataSlices, callBack);
                } else {
                    ExcelHelper.getSliceAsync(file, ++nextSlice, sliceCount, gotAllSlices, docDataSlices, slicesReceived, callBack);
                }
            } else {
                gotAllSlices = false;
                file.closeAsync();
                console.error("ERROR getSliceAsync:", result.error.message);
                callBack(null, result.error.message);
            }
        });
    }

    static getDocumentAsBase64(callBack: (bytes: Uint8Array, error: string) => void) {
        Office.context.document.getFileAsync(Office.FileType.Compressed, { sliceSize: 65536 /*64 KB*/ }, 
            function (result) {
                if (result.status == Office.AsyncResultStatus.Succeeded) {
                    // If the getFileAsync call succeeded, then result.value will return a valid File Object.
                    const file = result.value;
                    const sliceCount = file.sliceCount;
                    const docDataSlices = [];
                    let slicesReceived = 0, gotAllSlices = true;
                    // Get the file slices.
                    ExcelHelper.getSliceAsync(file, 0, sliceCount, gotAllSlices, docDataSlices, slicesReceived, callBack);
                } else {
                    console.error("ERROR getDocumentAsCompressed:", result.error.message);
                    callBack(null, result.error.message);
                }
        });
    }
    // #endregion Get file content

    // #region Get workbook bame
    static async getWorkBookName(): Promise<string> {
        try {
            return await Excel.run(async (context) => {
                const workbook = context.workbook;
                workbook.load("name")
                await context.sync();
                return workbook.name;
            });
        } catch (error) {
            console.error("ERROR getWorkBookName: ", error);
            return null;
        }
    }
    // #endregion Get workbook bame

}