import { DefaultButton, FocusTrapZone, Layer, Overlay, Popup, PrimaryButton, Stack } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import { DialogType } from "../../models/common/enums";

//Style
const StyledPopup = styled(Popup)`
    background: rgba(0, 0, 0, 0.2);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 999;
`

const StyledOverlay = styled(Overlay)`
    background: none;
`

const DialogContent = styled.div(
  {
    padding: 10,
    background: "#fff",
    width: "90%",
    position: "absolute",
    top: "30%",
    left: "2%",

    "& > .title": {
      h2: {
        marginTop: 0
      }
    },

    "& > .body": {
      paddingBottom: 20
    }
  }
)

//Component
export interface IDialogButton {
  title?: any;
  classes?: string;
  disabled?: boolean;
  primary?: boolean;
  tooltip?: string;
  icon?: string;
  onClick: () => void;
}

interface DialogProps {
  dialogType: DialogType;
  title: string | JSX.Element;
  body: string | JSX.Element;
  buttons: IDialogButton[];
  onClose?: () => void;
}

export const Dialog = (props: DialogProps) => {
  const handleCloseClick = () => {
    if (!props.onClose) return;
    props.onClose();
  };

  const handleButtonClick = (button: IDialogButton) => {
    if (!button) return;
    if (button.disabled) return;
    button.onClick();
  };

  const dialogButtons: JSX.Element[] = [];
  for (let i = 0; i < props.buttons.length; i++) {
    const button = props.buttons[i];
    if (button.onClick === null) continue;
    if (button.primary) {
      dialogButtons.push(<PrimaryButton key={i} className={button.classes} text={button.title} title={button.tooltip} disabled={button.disabled} onClick={() => handleButtonClick(button)}></PrimaryButton>)
    } else {
      dialogButtons.push(<DefaultButton key={i} className={button.classes} text={button.title} title={button.tooltip} disabled={button.disabled} onClick={() => handleButtonClick(button)}></DefaultButton>)
    }
  }

  return (
    <Layer>
      <StyledPopup
        className={props.dialogType}
        role="dialog"
        aria-modal="true"
        onDismiss={handleCloseClick}
      >
        <StyledOverlay onClick={handleCloseClick} />
        <FocusTrapZone>
          <DialogContent role="document">
            <Stack className="title">
              <h2>{props.title}</h2>
            </Stack>
            <Stack className="body">
              {props.body}
            </Stack>
            <Stack className="buttons" horizontal tokens={{ childrenGap: 10 }}>
              {dialogButtons}
            </Stack>
          </DialogContent>
        </FocusTrapZone>
      </StyledPopup>
    </Layer>
  );
}

export default Dialog;