export interface IUseCaseDataSetTableData {
    name: string;
    data: any[];
}

export class UseCaseDataSetTableData implements IUseCaseDataSetTableData {
    name: string;
    data: any[];

    constructor();
    constructor(obj: IUseCaseDataSetTableData);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.data = obj && obj.data || [];
    }
}
