import App from "./components/App";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { PartialTheme, ThemeProvider } from "@fluentui/react";
import * as React from "react";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./store";
import { Global, css } from '@emotion/react'
import { appTheme } from "./components/appTheme";

/* global document, Office, module, require */

initializeIcons();

let isOfficeInitialized = false;

//Global Styles
const globalStyle = css({
  ":root, body": {
    width: "100%",
    height: "100vh",
    margin: 0,
    padding: 0,
    backgroundColor: "#fff",
    scrollbarWidth: "thin"
  },
  //Fluent UI
  ".ms-ChoiceFieldGroup": {
    "&.horizontal": {
      ".ms-ChoiceFieldGroup-flexContainer": {
          display: "flex",
          ".ms-ChoiceField:not(last-child)": {
              marginRight: 15
          }
      }
    }
  },
  ".themeProvider": {
      minHeight: "100vh"
  }
})

//App
const title = "Aiddocs Office Add-in";

const render = (Component) => {
  ReactDOM.createRoot(document.getElementById("container")!).render(
    <ThemeProvider className="themeProvider" theme={appTheme}>
      <Provider store={store}>
        <Global
          styles={globalStyle}
        />        
        <BrowserRouter basename={APP_BASE_NAME}>
          <Component title={title} isOfficeInitialized={isOfficeInitialized} />
        </BrowserRouter>            
      </Provider>
    </ThemeProvider>,
  );
};

/* Render application after Office initializes */
Office.onReady(() => {
  isOfficeInitialized = true;
  render(App);
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    render(NextApp);
  });
}
