import { TemplateStateCode } from "../common/enums";
import { Translations } from "../common/translations";
export interface ITemplate {
    id: string;
    code: string;
    name: string;
    comment: string;
    modifyDate: number;
    group: string;
    keywords: string;
    formats: string[];
    groupName: string;
    thumbnail: string;
    thumbnailText: string;
    stateCode: TemplateStateCode;
}

export class Template implements ITemplate {
    id: string;
    code: string;
    name: string;
    comment: string;
    modifyDate: number;
    group: string;
    keywords: string;
    formats: string[];
    groupName: string;
    thumbnail: string;
    thumbnailText: string;
    stateCode: TemplateStateCode;

    constructor();
    constructor(obj: ITemplate);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.code = obj && obj.code || "";
        this.name = obj && obj.name || "";
        this.comment = obj && obj.comment || "";
        this.modifyDate = obj && obj.modifyDate || 0;
        this.group = obj && obj.group || Translations.UnnamedGroup;
        this.formats = obj && obj.formats || [];
        this.groupName = obj && obj.groupName || Translations.UnnamedGroup;
        this.thumbnail = obj && obj.thumbnail || "";
        this.thumbnailText = obj && obj.thumbnailText || Translations.Loading;
        this.stateCode = obj && obj.stateCode || TemplateStateCode.Created;
    }
}