import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { RootState } from "../../store";

export function OwnerRoute() {
    const ownerCode = useSelector((state: RootState) => state.auth.authUser?.ownerCode);

    return ownerCode
        ? <Outlet />
        : <Navigate to="/selectowner" />;
}
