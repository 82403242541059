import { remove } from "jszip";
import { Base } from "../../framework/base";

export interface INameValue {
    name: string;
    value: any;
}

export class NameValue implements INameValue {
    name: string;
    value: any;

    constructor();
    constructor(obj: INameValue);
    constructor(obj?: any) {
        this.name = obj && obj.name || "";
        this.value = obj && obj.value || null;
    }

    static getValue<T>(items: INameValue[], name: string, defaultValue: T): T {
        if (!items) return defaultValue;
        const item = items.find(i => i.name === name);
        if (!item) return defaultValue;
        if (Base.isNullOrUndefined(item.value)) return defaultValue;
        return item.value as T;
    }
    
    static getBooleanValue(items: INameValue[], name: string, defaultValue: boolean): boolean {
        const item = items.find(i => i.name === name);
        if (Base.isNullOrUndefined(item.value)) return defaultValue;
        if (Base.isBoolean(item.value)) return item.value;
        if (Base.isString(item.value)) return Base.strToBool(item.value);
        return !!item.value;
    }
}