import styled from "@emotion/styled";
import { IStackProps, Stack } from "@fluentui/react";
import React from "react";
import { ReactNode } from "react";

const StyledStack = styled(Stack)`
    padding-bottom: 15px;
`

export const FormLine = (props: { children?: ReactNode } & IStackProps) => {
  return (
    <StyledStack {...props}>
      {props.children}
    </StyledStack>
  );
}

export default FormLine;