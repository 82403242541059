import { Base } from "../../framework/base";
import { IUseCaseDataSetListItem, UseCaseDataSetListItem } from "./useCaseDataSetListItem";

export interface IUseCaseDataSetListItems {
    items: IUseCaseDataSetListItem[];
}

export class UseCaseDataSetListItems implements IUseCaseDataSetListItems {
    items: UseCaseDataSetListItem[];

    constructor();
    constructor(obj: IUseCaseDataSetListItems);
    constructor(obj?: any) {
        this.items = Base.getTypedArray(obj ? obj.items : null, UseCaseDataSetListItem);
    }
}
