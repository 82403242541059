import { Base } from "../../framework/base";
import { AuthorizationLevel } from "../common/enums";

export interface IAuthUser {
    id: string;
    name: string;
    ownerCode: string;
    ownerName: string;
    authorizationLevel: AuthorizationLevel;
    hasMultipleOwners: boolean;
    token: string;
    refreshToken: string;
}

export class AuthUser implements IAuthUser {
    id: string;
    name: string;
    ownerCode: string;
    ownerName: string;
    authorizationLevel: AuthorizationLevel;
    hasMultipleOwners: boolean;
    token: string;
    refreshToken: string;

    constructor();
    constructor(obj: IAuthUser);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
        this.ownerCode = obj && obj.ownerCode || "";
        this.ownerName = obj && obj.ownerName || "";
        if (obj && !Base.isNullOrUndefined(obj.authorizationLevel)) {
            this.authorizationLevel = obj && obj.authorizationLevel;
        } else {
            this.authorizationLevel = AuthorizationLevel.Reader;
        }
        this.hasMultipleOwners = obj && obj.hasMultipleOwners || false;
        this.token = obj && obj.token || "";
        this.refreshToken = obj && obj.refreshToken || "";
    }
}