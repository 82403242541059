import { DocumentCard, DocumentCardActions, DocumentCardDetails, DocumentCardTitle, IButtonProps, Label, Stack } from "@fluentui/react";
import * as React from "react";
import { useNavigate } from "react-router-dom";
import styled from "@emotion/styled";
import { Translations } from "../../models/common/translations";
import { callServiceOnRemove, hasPermission, navigateWithSetReturnUrl, showConfirmationDialog, templateCanBeCopied, templateCanBeRemoved, templateStateCanBeChanged } from "../../models/common/appUtils";
import { ITemplate } from "../../models/template/template";
import { RootState, useAppDispatch } from "../../store";
import { templateActions } from "../../store/template";
import { useSelector } from "react-redux";
import { AuthorizationLevel, ConfirmationDialogResult } from "../../models/common/enums";
import { Base } from "../../framework/base";
import { removeTemplate } from "../../models/services/templateService";

//Styles
const StyledDocumentCard = styled(DocumentCard)`
    margin-top: 10px;
    height: 200px;
    max-width: 100%;
    display: flex;
`

const StyledDocumentCardDetails = styled(DocumentCardDetails)`
    overflow: auto;
    padding: 5px;
`

//Styles
const TemplateDiv = styled.div(
    {
        width: 131,
        height: 200,
        marginLeft: 2,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
)

const Thumbnail = styled.div(
    {
        height: 185,
        marginBottom: 5,
        display: "flex",
        alignItems: "center",

        "&.withText": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 185,
            border: "1px solid #333"
        },

        img: {
            border: "1px solid #333",
            objectFit: "contain",
            width: "100%"
        },

        div: {
            textAlign: "center"
        }
    }
)

//Component
export interface ITemplateDetailCardProps {
    returnUrl: string;
    template: ITemplate;
}

export const TemplateDetailCard = (props: ITemplateDetailCardProps) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const templateListId = useSelector((state: RootState) => state.template.templateListId);
    const [actionItems, setActionItems] = React.useState<IButtonProps[]>([]);

    const handleCopyTemplate = () => {
        dispatch(templateActions.setTemplateCopy({ template: props.template, returnUrl: props.returnUrl }));
        navigate("/templatecopy");
    }

    const handleEditTemplate = () => {
        navigateWithSetReturnUrl(navigate, "/templateedit/" + props.template.code, props.returnUrl);
    }

    const handleActions = () => {
        const items: IButtonProps[] = [];
        if (hasPermission(authUser, AuthorizationLevel.Reader)) {
            if (templateCanBeCopied(authUser, props.template)) {
                items.push({ iconProps: { iconName: "Copy" }, text: Translations.Copy, onClick: handleCopyTemplate });
            }
            const canEdit = templateStateCanBeChanged(authUser, props.template);
            items.push({ iconProps: { iconName: canEdit ? "Edit" : "OpenFolderHorizontal" }, text: canEdit ? Translations.Edit : Translations.Open, onClick: handleEditTemplate });
            if (templateCanBeRemoved(authUser, props.template)) {
                items.push({ iconProps: { iconName: "Delete" }, text: Translations.Remove, onClick: handleRemoveTemplate });
            }
        }
        setActionItems(items);
    }

    const removeTemplateFromDb = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.TemplateRemoveConfirmation, props.template.name), Translations.RemoveTemplate);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeTemplate(props.template.id))
        if (!dbResult) return;
        dispatch(templateActions.setTemplateListId(templateListId + 1));
    }

    const handleRemoveTemplate = () => {
        removeTemplateFromDb();
    }

    React.useEffect(() => {
        handleActions();
    }, [props.template]);

    return (
        <StyledDocumentCard>
            <TemplateDiv>
                <Thumbnail className={props.template.thumbnail ? "" : " withText"} >{props.template.thumbnail
                    ? <img src={"data:image/jpg;base64," + props.template.thumbnail} alt={Translations.Thumbnail} ></img>
                    : <div><span>{props.template.thumbnailText}</span></div>}
                </Thumbnail>
            </TemplateDiv>
            <Stack style={{flex: 1}}>
                <DocumentCardTitle title={props.template.name} />
                <StyledDocumentCardDetails>
                    <Label>{Translations.Code}</Label>
                    <div>{props.template.code}</div>
                    <Label>{Translations.Name}</Label>
                    <div>{props.template.name}</div>
                    <Label>{Translations.State}</Label>
                    <div>{props.template.stateCode}</div>
                    <Label>{Translations.Comment}</Label>
                    <div>{props.template.comment}</div>
                    <Label>{Translations.Format}</Label>
                    <div>{props.template.formats}</div>
                </StyledDocumentCardDetails>
                <DocumentCardActions actions={actionItems} />
            </Stack>
        </StyledDocumentCard>
    );
}

export default TemplateDetailCard;
