import { TooltipHost } from "@fluentui/react";
import * as React from "react";
import { ReactNode } from "react";

export const AppTooltip = (props: { children?: ReactNode, text: string }) => {
  if (!props.text) {
    return <>{props.children}</>;
  }
  return (
    <TooltipHost
      content={props.text}
      styles={{ root: { display: "inline-block", width: "100%" } }}
      calloutProps={{
        gapSpace: 0,
        styles: {
          beak: { background: "#E1EEF4" },
          beakCurtain: { background: "#E1EEF4" },
          calloutMain: { background: "#E1EEF4" },
        }
      }}
    >
      {props.children}
    </TooltipHost>
  );
}

export default AppTooltip;