import { Base } from "../../framework/base";
import { IUseCaseListItem, UseCaseListItem } from "./useCaseListItem";

export interface IUseCaseListItems {
    items: IUseCaseListItem[];
}

export class UseCaseListItems implements IUseCaseListItems {
    items: UseCaseListItem[];

    constructor();
    constructor(obj: IUseCaseListItems);
    constructor(obj?: any) {
        this.items = Base.getTypedArray(obj ? obj.items : null, UseCaseListItem);
    }
}