import * as React from "react";
import { IImageProps, Label, Stack, IconButton } from "@fluentui/react";
import { Translations } from "../../models/common/translations";
import styled from "@emotion/styled";
import FileIconButton from "./FileIconButton";

//Style
const FileNameContainer = styled.div`
    flex-grow: 1;
    display: flex;
    align-items: center;
    padding-right: 10px;
    overflow: hidden;
`

//Component
export interface IFormFileFieldProps {
  accept?: string;
  label?: string;
  fileName?: string;
  onFileDownload: () => void;
  onFileUpload: (filePar: File) => void;
  onExcelUpload?: () => void;
}

export const FormFileField = (props: IFormFileFieldProps & IImageProps) => {
  return (
    <React.Fragment>
        {props.label &&
          <Label>{props.label}</Label>
        }
        <Stack horizontal>
            <FileNameContainer title={props.fileName}>{props.fileName}</FileNameContainer>
            {!!props.fileName && !!props.onFileDownload &&
                <IconButton iconProps={{ iconName: "Download" }} title={Translations.Download} ariaLabel={Translations.Download} onClick={props.onFileDownload} />
            }
            {!!props.onFileUpload &&
              <FileIconButton title={Translations.Upload} onFileChange={props.onFileUpload} accept={props.accept} />
            }
            {!!props.onExcelUpload &&
                <IconButton iconProps={{ iconName: "ExcelLogo" }} title={Translations.UploadCurrentlyOpenExcel} ariaLabel={Translations.UploadCurrentlyOpenExcel} onClick={props.onExcelUpload} />
            }
        </Stack>
    </React.Fragment>
  );
}
