export interface IDocumentCreateResultBase64 {
    document: string;
}

export class DocumentCreateResultBase64 implements IDocumentCreateResultBase64 {
    document: string;

    constructor();
    constructor(obj: IDocumentCreateResultBase64);
    constructor(obj?: any) {
        this.document = obj && obj.document || "";
    }
}