import { DirectionalHint, IButtonProps, IconButton, IContextualMenuProps, Stack } from "@fluentui/react";
import * as React from "react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Translations } from "../../models/common/translations";
import { IUseCaseListItem } from "../../models/excel/useCaseListItem";
import { getUseCaseListItems, removeUseCase } from "../../models/services/useCaseService";
import { callService, callServiceOnRemove, hasPermission, navigateWithSetReturnUrl, showConfirmationDialog, useCaseCanBeAdded } from "../../models/common/appUtils";
import styled from "@emotion/styled";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { Base } from "../../framework/base";
import UseCasesListItem from "./UseCasesListItem";
import PageSubTitle from "../common/PageSubTitle";
import { useCaseActions } from "../../store/useCase";
import DetailCard from "../common/DetailCard";
import { AuthorizationLevel, ConfirmationDialogResult } from "../../models/common/enums";

//Styles
const UseCase = styled.div(
    {
        height: "calc(100vh - " + (450).toString(10) + "px)", 
        overflowY: "auto", 
        overflowX: "hidden"
    }
)

//Component
export const UseCasesList = ({tabKey}) => {
    const dispatch = useAppDispatch();
    const buttonRef = React.useRef(null);
    const navigate = useNavigate();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const managementMode = useSelector((state: RootState) => state.app.managementMode);
    const [useCaseListItems, setUseCaseListItems] = useState<IUseCaseListItem[]>([]);
    const selectedUseCase = useSelector((state: RootState) => state.useCase.selectedUseCase);
    const returnUrl = `/${tabKey}`;
    const [actionItems, setActionItems] = React.useState<IButtonProps[]>([]);

    const loadInitialData = async () => {
        const newUseCaseListItems = await callService(() => getUseCaseListItems())
        if (!newUseCaseListItems) return
        setUseCaseListItems(newUseCaseListItems.items);
        dispatch(useCaseActions.setSelectedUseCase(newUseCaseListItems.items[0]));
    }

    const handleAddUseCase = () => {
        navigateWithSetReturnUrl(navigate, "/usecaseedit/"  + Base.emptyGuid, returnUrl);
    }

    const menuItems: IContextualMenuProps = {
        directionalHint: DirectionalHint.bottomRightEdge,
        directionalHintFixed: true,
        items: [
            {
                key: "refresh",
                text: Translations.RefreshList,
                iconProps: { iconName: "Refresh" },
                onClick: () => {
                    refreshList();
                    return true;
                }
            }
        ].concat(useCaseCanBeAdded(authUser) && managementMode
            ? [
                {
                    key: "add",
                    text: Translations.AddUseCase,
                    iconProps: { iconName: "Add" },
                    onClick: () => {
                        handleAddUseCase();
                        return true;
                    }
                }
            ]
            : [])
    }

    const handleEdit = () => {
        navigateWithSetReturnUrl(navigate, "/usecaseedit/" + selectedUseCase.id, returnUrl);
    }

    const handleRemove = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.UseCaseRemoveConfirmation, selectedUseCase.name), Translations.RemoveUseCase);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeUseCase(selectedUseCase.id))
        if (!dbResult) return;
        refreshList();
    }

    const handleActions = () => {
        const items: IButtonProps[] = [];
        if (hasPermission(authUser, AuthorizationLevel.User)) {
            items.push({ iconProps: { iconName: "Edit" }, text: Translations.Edit, onClick: handleEdit });
            items.push({ iconProps: { iconName: "Delete" }, text: Translations.Remove, onClick: handleRemove });
        }
        setActionItems(items);
    }

    useEffect(() => {
        loadInitialData();
    }, [authUser?.ownerCode]);

    const refreshList = async () => {
        await loadInitialData();
    }

    React.useEffect(() => {
        handleActions();
    }, [selectedUseCase]);
    

    return (
        <React.Fragment>
            <PageSubTitle
                text={Translations.UseCases}
                suffix={managementMode ?
                    <Stack horizontal>
                        <div
                            onClick={(e) => e.stopPropagation()}
                            ref={buttonRef}> <IconButton
                                iconProps={{ iconName: "More" }}
                                onRenderMenuIcon={() => null}
                                title={Translations.More}
                                ariaLabel={Translations.More}
                                menuProps={menuItems}
                                style={{ marginRight: "10px" }} />
                        </div>
                    </Stack> : null}
            />
            <UseCase>
                {useCaseListItems.map((item) =>
                    <UseCasesListItem
                        key={item.id}
                        item={item}
                    />
                )}
            </UseCase>
            {selectedUseCase &&
                    <DetailCard
                    actionItems={actionItems}
                    description={selectedUseCase.description}
                    id={selectedUseCase.id}
                    image={selectedUseCase.image}
                    title={selectedUseCase.name}
                />
                }
        </React.Fragment>
    );
}

export default UseCasesList;