import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { Translations } from "../../models/common/translations";
import { DialogType } from "../../models/common/enums";
import { IDialogButton } from "../../components/common/Dialog";

export interface IAppState {
    spinner: { show: boolean, text: string | null };
    error: { text: string | null };
    success: { text: string | null };
    dialog: { show: boolean, type: DialogType, title: string, text: string, buttons?: IDialogButton[] }
    returnUrl: string | null;
    managementMode: boolean;
}

const appSlice = createSlice({
    name: "app",
    initialState: createInitialState(),
    reducers: {
        //Spinner
        showSpinner(state: IAppState, action?: PayloadAction<string>) {
            state.spinner = { show: true, text: action?.payload ?? Translations.Loading };
        },
        hideSpinner(state: IAppState) {
            state.spinner = { show: false, text: null };
        },
        //Error
        showError(state: IAppState, action: PayloadAction<string>) {
            state.error = { text: action?.payload };
        },
        hideError(state: IAppState) {
            state.error = { text: null };
        },
        //Success
        showSuccess(state: IAppState, action: PayloadAction<string>) {
            state.success = { text: action?.payload };
        },
        hideSuccess(state: IAppState) {
            state.success = { text: null };
        },
        //Dialog
        showDialog(state: IAppState, action: PayloadAction<{ type: DialogType, title: string, text: string, buttons?: IDialogButton[] }>) {
            state.dialog = { show: true, type: action.payload.type, title: action.payload.title, text: action.payload.text, buttons: action.payload.buttons };
        },
        hideDialog(state: IAppState) {
            state.dialog = { show: false, type: DialogType.Info, title: null, text: null };
        },
        //ReturnUrl
        setReturnUrl(state: IAppState, action: PayloadAction<string>) {
            state.returnUrl = action.payload;
        },
        //ManagementMode
        setManagementMode(state: IAppState, action: PayloadAction<boolean>) {
            state.managementMode = action.payload;
        },
    },
});

function createInitialState(): IAppState {
    return {
        spinner: { show: false, text: null },
        error: { text: null },
        success: { text: null },
        dialog: { show: false, type: DialogType.Info, title: null, text: null, buttons: null },
        returnUrl: null,
        managementMode: false
    }
}

export const appActions = { ...appSlice.actions };
export const appReducer = appSlice.reducer;
export default appSlice.reducer;