import { localDb } from "../storage/localDb";

export interface IApplicationSettings {
    serviceUrl: string;
}

export class ApplicationSettings implements IApplicationSettings {
    serviceUrl: string;

    constructor();
    constructor(obj: IApplicationSettings);
    constructor(obj?: any) {
        this.serviceUrl = obj && obj.serviceUrl || "";
    }

    static readSettings(): IApplicationSettings {
        const result = new ApplicationSettings();
        result.serviceUrl = localDb.getValue("Aiddocs.ApplicationServiceUrl", "https://dev.aiddocs.com/clientExcelApi");
        return result;
    }

    static saveSettings(settings: IApplicationSettings) {
        localDb.setValue("Aiddocs.ApplicationServiceUrl", settings.serviceUrl);
    }

    static settingsAreSpecified(): boolean {
        const settings = this.readSettings();
        return !!settings.serviceUrl;
    }
}