import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { useAppDispatch } from "../../store";
import { appActions } from "../../store/app";
import { Translations } from "../../models/common/translations";

//Style
const MessageContainer = styled.div(
  {
    position: "fixed",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    zIndex: "1000001",

    "& > *": {
      marginLeft: "5%",
      marginRight: "5%",
      width: "90%",
      position: "absolute",
      top: "30%"
    },
  }
)

//Component
export interface IMessageProps {
  text: string | JSX.Element;
  type: MessageBarType;
}

export const Message = (props: IMessageProps) => {
  const successTimeOut = useRef(0);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (props.type === MessageBarType.success) {
      clearTimeout(successTimeOut.current);
      successTimeOut.current = window.setTimeout(() => {
        dispatch(appActions.hideSuccess());
      }, 2500);
    }

    return () => {
      clearTimeout(successTimeOut.current);
      successTimeOut.current = 0;
    };
  }, [props.text]);

  const handleCloseClick = () => {
    if (props.type === MessageBarType.error) {
      dispatch(appActions.hideError());
    } else if (props.type === MessageBarType.success) {
      dispatch(appActions.hideSuccess());
    }
  };

  return (
    <MessageContainer onClick={handleCloseClick}>
      <MessageBar
        messageBarType={props.type}
        isMultiline={true}
        onDismiss={handleCloseClick}
        dismissButtonAriaLabel={Translations.Close}
      >
        {props.text}
      </MessageBar>
    </MessageContainer>
  );
}

export default Message;