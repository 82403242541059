import { ApiResponse } from "../common/apiResponse";
import { ApiSaveSuccess } from "../common/apiSaveSuccess";
import { UseCaseListItems } from "../excel/useCaseListItems";
import { UseCaseEdit } from "../useCase/useCaseEdit";
import { postApiCall, postApiCallUnTyped, postFileBlobApiCall } from "./tokenService";

export const getUseCaseListItems = (): Promise<UseCaseListItems> => {
    return postApiCall("usecase/list", UseCaseListItems);
}

export const getUseCaseBase64Content = (id: string): Promise<string> => {
    return postApiCallUnTyped("usecase/content", JSON.stringify({ id }));
}

export const getUseCaseEdit = (id: string): Promise<UseCaseEdit> => {
    return postApiCall("usecase/edit", UseCaseEdit, JSON.stringify({ id }));
}

export const getUseCaseExcelFile = (id: string): Promise<Blob> => {
    return postFileBlobApiCall("usecase/excel", JSON.stringify({ id }));
}

export const saveUseCase = (data: any): Promise<ApiSaveSuccess> => {
    return postApiCall("usecase/save", ApiSaveSuccess, JSON.stringify(data));
}

export const saveUseCaseExcel = (data: any): Promise<ApiSaveSuccess> => {
    return postApiCall("usecase/saveexcel", ApiSaveSuccess, JSON.stringify(data));
}

export const removeUseCase = (id: string): Promise<ApiResponse> => {
    return postApiCall("usecase/remove?id=" + id, ApiResponse, JSON.stringify({ id }));
}
