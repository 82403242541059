import { ActionButton } from "@fluentui/react";
import * as React from "react";
import { useState } from "react";
import styled from "@emotion/styled";
import { Translations } from "../../models/common/translations";

//Styles
const ImageCarouselHeader = styled.div(
  {
    display: "flex",
    paddingBottom: 15,
    justifyContent: "center"
  }
)

const ImageCarouselHeaderTitle = styled.div(
  {
    paddingLeft: 15,
    paddingRight: 15,
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  }
)

const ImageCarouselFooter = styled.div(
  {
    display: "flex",
    paddingTop: 15,
    justifyContent: "center"
  }
)

const ImageCarouselImages = styled.div(
  {
    height: "82vw",

    "& > div": {
      cursor: "pointer",
      position: "absolute",
      transition: "transform 1s, left 1s, opacity 1s, z-index 0s",
      opacity: "1",
      display: "inline-block",

      img: {
        border: "solid 1px #979797",
        width: "60vw",
        transition: "width 1s",
      },

      "&.selected": {
        zIndex: "10",
        left: "50%",
        transform: "translateY(0) translateX(-50%)",
      },

      "&.prev": {
        zIndex: "5",
        left: "30%",
        transform: "translateY(50px) translateX(-50%)",

        img: {
          width: "40vw"
        }
      },

      "&.next": {
        zIndex: "5",
        left: "70%",
        transform: "translateY(50px) translateX(-50%)",

        img: {
          width: "40vw"
        }
      }
    }
  }
)

//Component
export interface IImageCarouselImage {
  id: string;
  image: string;
  tooltip: string;
}

interface IImageCarouselProps {
  images: IImageCarouselImage[];
  footerSuffix?: React.ReactNode;
  onDownload: () => void;
}

export const ImageCarousel = (props: IImageCarouselProps) => {
  const [index, setIndex] = useState<number>(0);

  React.useEffect(() => {
    if (index < props.images.length) return;
    setIndex(Math.max(0, props.images.length - 1));
  }, [props.images.length]);

  const handleMovePrevious = () => {
    if (index < 1) return;
    setIndex(index - 1);
  };

  const handleMoveNext = () => {
    if (index >= props.images.length - 1) return;
    setIndex(index + 1);
  }

  if (props.images.length < 1) {
    return null;
  }

  return (
    <div className="imageCarousel">
      <ImageCarouselHeader>
        <ActionButton iconProps={{ iconName: "ChevronLeft" }} disabled={index < 1} onClick={handleMovePrevious} title={Translations.PreviousPage}></ActionButton>
        <ImageCarouselHeaderTitle>{(index + 1).toString(10) + " " + Translations.Of + " " + props.images.length}</ImageCarouselHeaderTitle>
        <ActionButton iconProps={{ iconName: "ChevronRight" }} disabled={index >= props.images.length - 1} onClick={handleMoveNext} title={Translations.NextPage}></ActionButton>
      </ImageCarouselHeader>
      <ImageCarouselImages>
        {props.images.map((image, ind) => {
          if (ind < index - 1 || ind > index + 1) {
            return null;
          }
          const isPrev = ind === index - 1;
          const isNext = ind === index + 1;
          return <div key={image.id} className={(index === index ? " selected" : "") + (isPrev ? " prev" : "") + (isNext ? " next" : "")}
            title={image.tooltip} onClick={isNext ? handleMoveNext : (isPrev ? handleMovePrevious : props.onDownload)}>
            <img src={"data:image/png;base64," + image.image}></img>
          </div>;
        })}
      </ImageCarouselImages>
      <ImageCarouselFooter>
        {!!props.onDownload &&
          <ActionButton iconProps={{ iconName: "Download" }} disabled={!props.images.length} onClick={props.onDownload} title={Translations.Download}>{Translations.Download}</ActionButton>
        }
        {props.footerSuffix}
      </ImageCarouselFooter>
    </div>
  );
}

export default ImageCarousel;