import { ApiResponse, IApiResponse } from "./apiResponse";

export interface IApiError extends IApiResponse {
    status: number;
}

export class ApiError extends ApiResponse implements IApiError {
    status: number;

    constructor();
    constructor(obj: IApiError);
    constructor(obj?: any) {
        super(obj);
        this.status = obj && obj.status || 200;
    }
}