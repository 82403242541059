import { IOwnerListItem, OwnerListItem } from ".";
import { Base } from "../../framework/base";

export interface IOwnerListItems {
    items: IOwnerListItem[];
}

export class OwnerListItems implements IOwnerListItems {
    items: OwnerListItem[];

    constructor();
    constructor(obj: IOwnerListItems);
    constructor(obj?: any) {
        this.items = Base.getTypedArray(obj ? obj.items : null, OwnerListItem);
    }
}