import { Navigate, useNavigate } from "react-router-dom";
import React, { useEffect } from "react";
import { ExcelFileSettings } from "../../models/excel/excelFileSettings";

export function DefaultRoute() {
    const navigate = useNavigate();

    const navigateToDefault = async () => {
        const exelFileSettings = await ExcelFileSettings.readSettings();
        if (exelFileSettings.useCaseId) {
            navigate("/usecaseopen/" + exelFileSettings.useCaseId);
        } else {
            navigate("/");
        }
    };

    useEffect(() => {
        navigateToDefault();
    }, []);

    return <Navigate to="/" />;
}
