import * as React from "react";
import { ITextFieldProps, TextField } from "@fluentui/react";
import { Control, Controller, UseControllerProps } from "react-hook-form";

//Component
export interface IFormTextFieldProps {
  label: string;
  name: string;
  control: Control<any>;
  rules?: UseControllerProps["rules"];
  defaultValue?: any;
}

export const FormTextField = (props: IFormTextFieldProps & ITextFieldProps) => {
  return (
    <Controller name={props.name} control={props.control} rules={props.rules} defaultValue={props.defaultValue || ""} render={({ field, fieldState }) => (
      <TextField
        {...props}
        {...field}
        id={field.name}
        label={props.label}
        title={props.label}
        errorMessage={fieldState.error ? fieldState.error.message : ""}
      />
    )}
    />
  );
}

export default FormTextField;