import * as React from "react";
import styled from "@emotion/styled";
import { Label } from "@fluentui/react";

//Style
const SubTitleContainer = styled(Label)`
  display: flex;
  align-items: center;
  h3 {
    margin-right: auto;
  }
`

//Component
export interface IPageSubTitleProps {
  text: string;
  suffix?: React.ReactNode;
}

export const PageSubTitle = (props: IPageSubTitleProps) => {
  return (
    <SubTitleContainer>
      <h3 title={props.text}>{props.text}</h3>
      {props.suffix}
  </SubTitleContainer>);
}

export default PageSubTitle;