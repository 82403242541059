import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { Translations } from "../../models/common/translations";
import { ComboBox, Dropdown, IComboBox, IComboBoxOption, IDropdownOption, IconButton,  Label,  Pivot, PivotItem, SelectableOptionMenuItemType, Stack } from "@fluentui/react";
import TitleBar from "../common/TitleBar";
import { callService, callServiceOnRemove, callServiceOnSave, hasPermission, navigateBackWithReturnUrl, navigateWithSetReturnUrl, showConfirmationDialog, templateCanBeCopied, templateCanBeRemoved, templateCanBeUploaded, templateStateCanBeChanged } from "../../models/common/appUtils";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { getTemplateEdit, getTemplateFile, removeTemplate, setTemplateState, uploadTemplateFileContent } from "../../models/services/templateService";
import { ITemplateEditItem } from "../../models/template/templateEditItem";
import FormLine from "../common/FormLine";
import TemplateEditCcLine from "./TemplateEditCcLine";
import styled from "@emotion/styled";
import { FormFileField } from "../common/FormFileField";
import saveAs from "file-saver";
import { Base } from "../../framework/base";
import { AuthorizationLevel, ConfirmationDialogResult, TemplateFormat, TemplateStateCode } from "../../models/common/enums";
import TemplateEditTemplateLine from "./TemplateEditTermplateLine";
import { templateActions } from "../../store/template";
import { pivotStyles } from "../appTheme";

//Styles
const TemplateComboBox = styled(ComboBox)`
    margin-bottom: 10px;
`

const TemplateDropdown = styled(Dropdown)`
    margin-bottom: 10px;
`

const PlaceholdersContainer = styled(Stack)`
    max-width: 100%;
    overflow: hidden;
`

//Component
const TemplateEdit = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    let { code } = useParams();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);
    const [template, setTemplate] = useState<ITemplateEditItem>(null);
    const [selectedTemplate, setSelectedTemplate] = useState<ITemplateEditItem>(null);
    const [image, setImage] = useState<string>(null);

    const [templateMapping, setTemplateMapping] = useState<{ [code: string]: ITemplateEditItem }>(null);
    const [options, setOptions] = useState<IComboBoxOption[]>([]);

    const loadTemplate = async (initial: boolean) => {
        if (!code) return;
        const edit = await callService(() => getTemplateEdit(code))
        if (!edit) return;
        const item = edit.item;
        setTemplate(item);
        if (initial) {
            setSelectedTemplate(item);
        } else {
            if (selectedTemplate) {
                const newSelectedTemplate = item.findById(selectedTemplate.id);
                setSelectedTemplate(newSelectedTemplate);
            }
        }
        setImage(item.image ? "data:image/jpg;base64," + item.image : null);
    }

    useEffect(() => {
        loadTemplate(true);
    }, []);

    const initializeOptions = () => {
        if (!template) return;
        const newTemplates = template.getAsList(0);
        const newMapping: { [code: string]: ITemplateEditItem } = {};
        for (const template of newTemplates) {
            newMapping[template.id + template.relationId] = template;
        }
        setTemplateMapping(newMapping);
        let newOptions = newTemplates.map(i => { return { key: i.id + i.relationId, text: i.name, itemType: SelectableOptionMenuItemType.Normal }});
        if (newOptions.length > 1) {
            newOptions = [
                { key: 'Template', text: Translations.Template, itemType: SelectableOptionMenuItemType.Header },
                ...newOptions.slice(0, 1),
                { key: 'Parts', text: Translations.Parts, itemType: SelectableOptionMenuItemType.Header },
                ...newOptions.slice(1)
            ];
        }
        setOptions(newOptions)
    }

    useEffect(() => {
        initializeOptions();
    }, [template]);
    
    const onRenderOption = (item: IComboBoxOption): JSX.Element | null => {
        switch (item.itemType) {
          case SelectableOptionMenuItemType.Header:
          case SelectableOptionMenuItemType.Divider:
            return <span>{item.text}</span>;
          default:
            const newTemplate = templateMapping[item.key];
            if (!newTemplate) return null;
            return <TemplateEditTemplateLine item={newTemplate} />;
        }
    };

    const onChange = React.useCallback(
        (_event: React.FormEvent<IComboBox>, option?: IComboBoxOption, _index?: number): void => {
            if (!templateMapping) return;
            const key = option?.key;
            if (!key) return;
            const newValue = templateMapping[key];
            if (!newValue) return null;
            setSelectedTemplate(newValue);
      }, [templateMapping]);

    // const onChangeDropDown = (_event: React.FormEvent<HTMLDivElement>, item: IDropdownOption): void => {
    //     if (!templateMapping) return;
    //     const key = item?.key;
    //     if (!key) return;
    //     const newValue = templateMapping[key];
    //     if (!newValue) return null;
    //     setSelectedTemplate(newValue);
    // };

    const handleFileDownload = async () => {
        const id = selectedTemplate?.templateFileId;
        if (!id) return;
        const newExcelFile = await callService(() => getTemplateFile(id), false)
        if (!newExcelFile) return
        saveAs(newExcelFile, selectedTemplate.templateFileName);
    }

    const handleFileUpload = async (filePar: File) => {
        if (!selectedTemplate) return;
        if (!filePar) return;
        const base64 = await Base.blobToBase64(filePar);
        if (!base64) return;
        const saveData = {
            id: selectedTemplate.templateFileId,
            rowId: selectedTemplate.templateFileRowId,
            file: Base.removeDataUrlHeader(base64),
            fileName: filePar.name,
            modifyDate: new Date(filePar.lastModified).toISOString()
        };
        const result = await callServiceOnSave(() => uploadTemplateFileContent(saveData));
        if (!result) return;
        loadTemplate(false);
    }

    const handleCopy = () => {
        dispatch(templateActions.setTemplateCopy({ template: template, returnUrl: "/templateedit/" + code }));
        navigate("/templatecopy");
    }

    const handleShowHide = async () => {
        const result = await callServiceOnSave(() => setTemplateState(template.id, template.stateCode !== TemplateStateCode.Archived ? TemplateStateCode.Archived : TemplateStateCode.Created));
        if (!result) return;
        loadTemplate(false);
    }

    const handlRemove = async () => {
        const result = await showConfirmationDialog(Base.strFormat(Translations.TemplateRemoveConfirmation, template.name), Translations.RemoveTemplate);
        if (result !== ConfirmationDialogResult.Yes) return;
        const dbResult = await callServiceOnRemove(() => removeTemplate(template.id))
        if (!dbResult) return;
        closeView();
    }

    const closeView = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    }

    const handleClose = () => {
        closeView();
    }

    if (!template) return null;

    return (
        <div>
            <TitleBar
                title={Translations.EditTemplate}
            >
                <Stack horizontal className="buttons">
                    {selectedTemplate?.id === template.id && templateStateCanBeChanged(authUser, template) &&
                        <IconButton iconProps={{ iconName: template.stateCode !== TemplateStateCode.Archived ? "Hide" : "RedEye" }} title={template.stateCode !== TemplateStateCode.Archived ? Translations.Hide : Translations.Show} ariaLabel={template.stateCode !== TemplateStateCode.Archived ? Translations.Hide : Translations.Show} onClick={handleShowHide} />
                    }                    
                    {selectedTemplate?.id === template.id && templateCanBeCopied(authUser, template) &&
                        <IconButton iconProps={{ iconName: "Copy" }} title={Translations.Copy} ariaLabel={Translations.Copy} onClick={handleCopy} />
                    }                    
                    {selectedTemplate?.id === template.id && templateCanBeRemoved(authUser, template) &&
                        <IconButton iconProps={{ iconName: "Remove" }} title={Translations.Remove} ariaLabel={Translations.Remove} onClick={handlRemove} />
                    }                    
                    <IconButton iconProps={{ iconName: "ChromeClose" }} title={Translations.Close} ariaLabel={Translations.Close} onClick={handleClose} />
                </Stack>
            </TitleBar>
            <div>
                <TemplateComboBox
                    selectedKey={selectedTemplate ? selectedTemplate.id + selectedTemplate.relationId : ""}
                    label={Translations.Template}
                    options={options}
                    onRenderOption={onRenderOption}
                    onChange={onChange}
                />
                {/* <TemplateDropdown
                    selectedKey={selectedTemplate ? selectedTemplate.id + selectedTemplate.relationId : ""}
                    label={Translations.Template}
                    options={options}
                    onRenderOption={onRenderOption}
                    onChange={onChangeDropDown}
                /> */}
                {!!selectedTemplate &&
                    <Pivot
                        styles={pivotStyles}
                    >
                        <PivotItem headerText={Translations.Properties}>
                            <FormLine>
                                <Label>{Translations.Code}</Label>
                                <div>{selectedTemplate.code}</div>
                            </FormLine>                        
                            <FormLine>
                                <Label>{Translations.Name}</Label>
                                <div>{selectedTemplate.name}</div>
                            </FormLine>                        
                            <FormLine>
                                <Label>{Translations.State}</Label>
                                <div>{selectedTemplate.stateName}</div>
                            </FormLine>                        
                            <FormLine>
                                <FormFileField
                                    accept={selectedTemplate.templateFormat === TemplateFormat.Docx ? ".docx" : ".xlsx"}
                                    fileName={selectedTemplate.templateFileName}
                                    label={Translations.Resource}
                                    onFileDownload={handleFileDownload}
                                    onFileUpload={templateCanBeUploaded(authUser, template) ? handleFileUpload : null}
                                />
                            </FormLine>                        
                        </PivotItem>
                        <PivotItem headerText={Translations.Placeholders}>
                            <PlaceholdersContainer enableScopedSelectors horizontal tokens={{ childrenGap: 5 }}>
                                <Stack.Item grow>
                                    <Label>{Translations.Placeholders}</Label>
                                    {selectedTemplate.placeHolders.map(i => 
                                        <TemplateEditCcLine key={i.id} item={i} />
                                    )}
                                </Stack.Item>
                                <Stack.Item grow>
                                    <Label>{Translations.DataControls}</Label>
                                    {selectedTemplate.dataControls.map(i => 
                                        <TemplateEditCcLine key={i.id} item={i} />
                                    )}
                                </Stack.Item>
                            </PlaceholdersContainer>
                        </PivotItem>
                    </Pivot>
                }
            </div>
        </div>
    )
}

export default TemplateEdit;