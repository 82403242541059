import { remove } from "jszip";
import { Base } from "../../framework/base";
import { store } from "../../store";
import { appActions } from "../../store/app";
import { getErrorMessage } from "../services/baseService";
import { isIApiResponse } from "./apiResponse";
import { AuthorizationLevel, ConfirmationDialogResult, DialogType, TemplateStateCode } from "./enums";
import { Translations } from "./translations";
import { NavigateFunction } from "react-router-dom";
import { IAuthUser } from "../auth/authUser";

export const showSuccessMessage = (message: string) => {
    store.dispatch(appActions.showSuccess(message));
}

export const showErrorMessage = (message: string) => {
    store.dispatch(appActions.showError(message));
}

export const showApiErrorMessage = (e: any) => {
    showErrorMessage(getErrorMessage(e));
}

export const callService = async <T>(serviceCall: () => Promise<T>, showSuccess: boolean = true, disableLoadIndicator: boolean = false, onFinally: () => void = null, spinnerText: string = null): Promise<T> => {
    if (!disableLoadIndicator) {
        store.dispatch(appActions.showSpinner(spinnerText));
    }
    try {
        const result = await serviceCall();
        if (showSuccess) {
            if (isIApiResponse(result) && result.message) {
                showSuccessMessage(result.message);
            } else if (Base.isString(result) && result) {
                showSuccessMessage(result);
            }
        }
        return result;
    } catch (e) {
        showApiErrorMessage(e);
        return Base.getPromiseResult<T>(null);
    } finally {
        if (!disableLoadIndicator) {
            store.dispatch(appActions.hideSpinner());
        }
        if (onFinally) {
            onFinally();
        }
    }
}

export const callServiceOnSave = async <T>(serviceCall: () => Promise<T>, showSuccess: boolean = true, disableLoadIndicator: boolean = false, onFinally: () => void = null): Promise<T> => {
    return callService(serviceCall, showSuccess, disableLoadIndicator, onFinally, Translations.Saving);
}

export const callServiceOnRemove = async <T>(serviceCall: () => Promise<T>, showSuccess: boolean = true, disableLoadIndicator: boolean = false, onFinally: () => void = null): Promise<T> => {
    return callService(serviceCall, showSuccess, disableLoadIndicator, onFinally, Translations.Removing);
}

export const showConfirmationDialog = (message: string, title: string = null, showCancelButton: boolean = false): Promise<ConfirmationDialogResult> => {
    return new Promise<ConfirmationDialogResult>((resolve) => {
        store.dispatch(appActions.showDialog({
            type: DialogType.Warning,
            title: title ?? Translations.Warning,
            text: message,
            buttons: [
                {
                    title: Translations.Yes,
                    onClick: () => {
                        store.dispatch(appActions.hideDialog());
                        resolve(ConfirmationDialogResult.Yes);
                    }
                },
                {
                    title: Translations.No,
                    onClick: () => {
                        store.dispatch(appActions.hideDialog());
                        resolve(ConfirmationDialogResult.No);
                    }
                },
                {
                    title: Translations.Cancel,
                    onClick: showCancelButton
                        ? () => {
                            store.dispatch(appActions.hideDialog());
                            resolve(ConfirmationDialogResult.Cancel);
                        }
                        : null
                }
            ]
        }));
    });
}

export const handleFormDialogClose = async (isDirty: boolean, submitFormData: () => void, closeView: () => void) => {
    if (isDirty) {
        const result = await showConfirmationDialog(Translations.FormDataModifiedConfirmation, Translations.Warning, true);
        if (result === ConfirmationDialogResult.Yes) {
            submitFormData();
            return
        } else if (result === ConfirmationDialogResult.Cancel) {
            return;
        }
    }
    closeView();
}

export const navigateWithSetReturnUrl = (navigate: NavigateFunction, url: string, returnUrl: string) => {
    store.dispatch(appActions.setReturnUrl(returnUrl));
    navigate(url);
}

export const navigateBackWithReturnUrl = (navigate: NavigateFunction, returnUrl: string, url: string = "/") => {
    const localReturnUrl = returnUrl;
    if (localReturnUrl) {
        store.dispatch(appActions.setReturnUrl(null));
    }
    navigate(localReturnUrl ? localReturnUrl : url);
}

//Authorization
export const hasPermission = (authUser: IAuthUser, authorizationLevel: AuthorizationLevel): boolean => {
    if (Base.isNullOrUndefined(authUser?.authorizationLevel)) return false;
    return authUser.authorizationLevel <= authorizationLevel;
}

export const useCaseCanBeAdded = (authUser: IAuthUser): boolean => {
    return hasPermission(authUser, AuthorizationLevel.User)
}

export const templateCanBeCopied = (authUser: IAuthUser, template: { stateCode: TemplateStateCode }): boolean => {
    return template.stateCode !== TemplateStateCode.Archived && hasPermission(authUser, AuthorizationLevel.User)
}

export const templateStateCanBeChanged = (authUser: IAuthUser, template: { stateCode: TemplateStateCode }): boolean => {
    return template.stateCode !== TemplateStateCode.Published && hasPermission(authUser, AuthorizationLevel.User)
}

export const templateCanBeRemoved = (authUser: IAuthUser, template: { stateCode: TemplateStateCode }): boolean => {
    return template.stateCode === TemplateStateCode.Created && hasPermission(authUser, AuthorizationLevel.User)
}

export const templateCanBeUploaded = (authUser: IAuthUser, template: { stateCode: TemplateStateCode }): boolean => {
    return template.stateCode === TemplateStateCode.Created && hasPermission(authUser, AuthorizationLevel.User)
}
