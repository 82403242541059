import { MessageBar, MessageBarType } from "@fluentui/react";
import * as React from "react";
import { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import { Translations } from "../../models/common/translations";

//Style
const StyledMessageBar = styled(MessageBar)`
    width: 100%;
`

//Component
export interface IMessageLineProps {
  text: string | JSX.Element;
  type: MessageBarType;
  onDismiss: () => void;
}

export const MessageLine = (props: IMessageLineProps) => {
  const successTimeOut = useRef(0);

  useEffect(() => {
    if (props.type === MessageBarType.success) {
      clearTimeout(successTimeOut.current);
      successTimeOut.current = window.setTimeout(() => {
        props.onDismiss();

      }, 2500);
    }

    return () => {
      clearTimeout(successTimeOut.current);
      successTimeOut.current = 0;
    };
  }, [props.text]);

  return (
    <StyledMessageBar
      messageBarType={props.type}
      isMultiline={true}
      onDismiss={props.onDismiss}
      dismissButtonAriaLabel={Translations.Close}
    >
      {props.text}
    </StyledMessageBar>
  );
}

export default MessageLine;