import * as React from "react";
import { ISpinButtonProps, Position, SpinButton } from "@fluentui/react";
import { Control, Controller, UseControllerProps } from "react-hook-form";

//Component
export interface IFormIntFieldProps {
  label: string;
  name: string;
  control: Control<any>;
  rules?: UseControllerProps["rules"];
  defaultValue?: any;
}

export const FormIntField = (props: IFormIntFieldProps & ISpinButtonProps) => {
  return (
    <Controller name={props.name} control={props.control} rules={props.rules} defaultValue={props.defaultValue || 0} render={({ field }) => (
      <SpinButton
        {...props}
        {...field}
        id={field.name}
        label={props.label}
        title={props.label}
        labelPosition={Position.top}
      />
    )}
    />
  );
}

export default FormIntField;