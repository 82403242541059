import { INameValue, NameValue } from "../common/nameValue";
import { ExcelHelper } from "./excelHelper";

export interface IExcelFileSettings {
    useCaseId: string;
    autoOpenTaskPane: boolean;
}

export class ExcelFileSettings implements IExcelFileSettings {
    static useCaseIdKey = "Aiddocs.UseCaseId";
    static taskpaneId = "Office.AutoShowTaskpaneWithDocument";

    useCaseId: string;
    autoOpenTaskPane: boolean;

    constructor();
    constructor(obj: IExcelFileSettings);
    constructor(obj?: any) {
        this.useCaseId = obj && obj.useCaseId || "";
        this.autoOpenTaskPane = obj && obj.autoOpenTaskPane || false;
    }

    static async readSettings(): Promise<IExcelFileSettings> {
        const result = new ExcelFileSettings();
        const values = await ExcelHelper.getCustomProperties([
            ExcelFileSettings.useCaseIdKey
        ]);
        result.useCaseId = NameValue.getValue<string>(values, ExcelFileSettings.useCaseIdKey, "");
        result.autoOpenTaskPane = ExcelHelper.getDocumentSettingBooleanValue(ExcelFileSettings.taskpaneId);
        return result;
    }

    static saveSettings(settings: IExcelFileSettings) {
        const newCustomerPropetiesValues: INameValue[] = [
            { name: ExcelFileSettings.useCaseIdKey, value: settings.useCaseId }
        ];
        ExcelHelper.setCustomProperties(newCustomerPropetiesValues);
        if (settings.autoOpenTaskPane) {
            ExcelHelper.setDocumentSettingBooleanValue(ExcelFileSettings.taskpaneId, settings.autoOpenTaskPane);
        } else {
            ExcelHelper.removeDocumentSettingBooleanValue(ExcelFileSettings.taskpaneId);
        }
    }

    static async settingsAreSpecified(): Promise<boolean> {
        const settings = await this.readSettings();
        return !!settings.useCaseId;
    }
}