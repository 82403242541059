import styled from "@emotion/styled";
import { ContextualMenuItemType, DirectionalHint, IContextualMenuItem, IContextualMenuProps, IconButton } from "@fluentui/react";
import * as React from "react";
import { ReactNode, useEffect, useState } from "react";
import { Translations } from "../../models/common/translations";
import { useNavigate } from "react-router-dom";
import StateText from "./StateText";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { authActions } from "../../store/auth";

//Style
const TopBarDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const TitleBarDiv = styled.div(
  {
    marginTop: 10,
    display: "flex",

    "& > h2": {
      flexShrink: "2",
      overflow: "hidden",
      marginRight: 10,
      marginBottom: 0,
      marginTop: 0
    },
    
    "& > div.tc > h2, & > div.tc > h3": {
    },

    "button:first-of-type,.buttons:first-of-type": {
      marginLeft: "auto"
    }
  }
)

const TitleBarSubTitle = styled.h3(
  {
    marginTop: 0,
    marginBottom: 0,
  }
)

//Component
export interface ITitleBarProps {
  children?: ReactNode;
  title: string;
  subTitle?: string;
  menuItems?: IContextualMenuItem[];
}

export const TitleBar = (props: ITitleBarProps) => {
  const dispatch = useAppDispatch();
  const authUser = useSelector((state: RootState) => state.auth.authUser);
  const managementMode = useSelector((state: RootState) => state.app.managementMode);
  const navigate = useNavigate();
  const [menuProps, setMenuProps] = useState<IContextualMenuProps>(null);

  useEffect(() => {
    const items: IContextualMenuItem[] = props.menuItems ? props.menuItems.slice(0) : [];
    if (items.length) {
      items.push({ key: "Divider", itemType: ContextualMenuItemType.Divider });
    }
    items.push(
      {
        key: "logout",
        text: Translations.Logout,
        iconProps: { iconName: "SignOut" },
        onClick: () => {
          dispatch(authActions.logout());
          return true;
        }
      }
    );
    setMenuProps({
      items: items,
      directionalHint: DirectionalHint.bottomRightEdge,
      directionalHintFixed: true,
    });
  }, [(props.menuItems ?? []).map(i => i.key).join(","),props.menuItems]);

  const returnHome = () => {
    navigate("/");
  }

  return (
    <React.Fragment>
      <TopBarDiv>
        <IconButton iconProps={{ iconName: "Home" }} title={Translations.Home} ariaLabel={Translations.Home} onClick={() => returnHome()} />
        <StateText
          // text={APP_ENV_NAME}
          text={"DEV"}
          color="#EEEEEE"
        />
        {managementMode &&
          <StateText
            text={Translations.ManagementMode}
            color={"#FBC01040"}
          />
        }
        <IconButton iconProps={{ iconName: "More" }} onRenderMenuIcon={() => null} title={Translations.More} ariaLabel={Translations.More} menuProps={menuProps} style={{ marginRight: "10px" }} />
      </TopBarDiv>
      <TitleBarDiv>
        <h2 title={props.title}>{props.title}</h2>
        {props.children}
      </TitleBarDiv>
      {!!props.subTitle &&
        <TitleBarSubTitle title={props.subTitle}>{props.subTitle}</TitleBarSubTitle>
      }
    </React.Fragment>
  );
}

export default TitleBar;