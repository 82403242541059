import { Base } from "../../framework/base";
import { generationParameterContextKey, generationParameterRootElementNameKey, generationParameterTemplateGroupKey } from "../common/consts";
import { INameValue, NameValue } from "../common/nameValue";

export interface IUseCaseEditItem {
    id: string;
    useCaseDataId: string;
    ownerCode: string;
    name: string;
    description: string;
    excelFileName: string;
    tip: string;
    image: string;
    modifyUserCode: string;
    modifyDate: string;
    sortOrder: number;
    rowId: string;
    generationParameters: INameValue[];
}

export class UseCaseEditItem implements IUseCaseEditItem {
    id: string;
    useCaseDataId: string;
    ownerCode: string;
    name: string;
    description: string;
    excelFileName: string;
    tip: string;
    image: string;
    modifyUserCode: string;
    modifyDate: string;
    sortOrder: number;
    rowId: string;
    generationParameters: NameValue[];

    constructor();
    constructor(obj: IUseCaseEditItem);
    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.useCaseDataId = obj && obj.useCaseDataId || "";
        this.ownerCode = obj && obj.ownerCode || "";
        this.name = obj && obj.name || "";
        this.description = obj && obj.description || "";
        this.excelFileName = obj && obj.excelFileName || "";
        this.tip = obj && obj.tip || "";
        this.image = obj && obj.image || "";
        this.modifyUserCode = obj && obj.modifyUserCode || "";
        this.modifyDate = obj && obj.modifyDate || "";
        this.sortOrder = obj && obj.sortOrder || 0;
        this.rowId = obj && obj.rowId || "";
        this.generationParameters = Base.getTypedArray(obj ? obj.generationParameters : null, NameValue);
    }
}
