export interface IPrettifyXmlResult {
    xml: string;
    error: string;
    errorData: string;
}

export class Base {
    static LineBreak = "\n";

    static isNullOrUndefined(test: any): boolean {
        return test === null || test === undefined;
    }
    
    static getTimeStamp(): string {
        const date = new Date();
        const y = date.getFullYear();
        const m = date.getMonth() + 1;
        const d = date.getDate();
        const h = date.getHours();
        const n = date.getMinutes();
        const s = date.getSeconds();
        const z = date.getMilliseconds();
        return String(y) + String(m <= 9 ? "0" + m : m) + String(d <= 9 ? "0" + d : d) + '-' + String(h <= 9 ? "0" + h : h) + 
            String(n <= 9 ? "0" + n : n) + String(s <= 9 ? "0" + s : s) + '-' +
            String(z <= 9 ? "00" + z : (z <= 99 ? "0" + z : z));
    }

    //https://developer.mozilla.org/en-US/docs/Web/API/WindowBase64/Base64_encoding_and_decoding
    static b64EncodeUnicode(str: string) {
        // first we use encodeURIComponent to get percent-encoded UTF-8,
        // then we convert the percent encodings into raw bytes which
        // can be fed into btoa.
        return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(_match, p1) {
                return String.fromCharCode(parseInt("0x" + p1));
            }));
    }

    static isString(data: any): data is string {
        return typeof data === "string";
    }

    static isBoolean(data: any): data is boolean {
        return typeof data === "boolean";
    }

    static strToBool(value: string): boolean {
        return value === "1" || value.toLocaleLowerCase() === "true";
    }

    //------------------------------
    //Guid
    //------------------------------
    static emptyGuid = "00000000-0000-0000-0000-000000000000";

    static getGuid(): string {
        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === "x" ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        }).toUpperCase();
    }

    //------------------------------
    //Comparison
    //------------------------------
    static strCompare(a: string, b: string): number {
        if (Base.isNullOrUndefined(a) && Base.isNullOrUndefined(b)) return 0;
        if (Base.isNullOrUndefined(a)) return 1;
        if (Base.isNullOrUndefined(b)) return -1;
        return a.localeCompare(b, "fi");
    }

    //------------------------------
    //Json
    //------------------------------
    static isJsonString(str: string): boolean {
        if (!str) return false;
        try {
            const obj = JSON.parse(str);
            if (obj && typeof obj === "object") {
                return true;
            }
        } catch (e) {
            return false;
        }
        return true;
    }
    
    //------------------------------
    //XML
    //------------------------------
    static isValidXmlElementName(name: string): boolean {
        if (!name) return true;
        try {
            const xmlDoc = new DOMParser().parseFromString("<" + name + "></" + name + ">", 'application/xml');
            const errorNode = xmlDoc.querySelector("parsererror");
            return !errorNode;
        } catch(e) {
            console.error("isValidXmlElementName", e)
            return false;
        }
    }

    static prettifyXml(xml: string): IPrettifyXmlResult {
        const result: IPrettifyXmlResult = {
            xml: "",
            error: "",
            errorData: ""
        };
        try {
            const xmlDoc = new DOMParser().parseFromString(xml, 'application/xml');
            const errorNode = xmlDoc.querySelector("parsererror");
            if (errorNode) {
                console.log("prettifyXml", errorNode);
                result.error = errorNode.innerHTML.replace(/ page /g, " xml data ");
                errorNode.remove();
                result.errorData = new XMLSerializer().serializeToString(xmlDoc);
                result.xml = xml;
                return result;
            }
            const xsltDoc = new DOMParser().parseFromString([
                // describes how we want to modify the XML - indent everything
                '<xsl:stylesheet xmlns:xsl="http://www.w3.org/1999/XSL/Transform">',
                '  <xsl:strip-space elements="*"/>',
                '  <xsl:template match="para[content-style][not(text())]">', // change to just text() to strip space in text nodes
                '    <xsl:value-of select="normalize-space(.)"/>',
                '  </xsl:template>',
                '  <xsl:template match="node()|@*">',
                '    <xsl:copy><xsl:apply-templates select="node()|@*"/></xsl:copy>',
                '  </xsl:template>',
                '  <xsl:output indent="yes"/>',
                '</xsl:stylesheet>',
            ].join('\n'), 'application/xml');
            const xsltProcessor = new XSLTProcessor();
            xsltProcessor.importStylesheet(xsltDoc);
            const resultDoc = xsltProcessor.transformToDocument(xmlDoc);
            result.xml = new XMLSerializer().serializeToString(resultDoc);
        } catch (e) {
            console.error("prettifyXml", e)
        }
        return result;
    };

    //------------------------------
    //Generics
    //------------------------------
    static getTypedArray<T>(items: any, type: (new (...args: any[]) => T)): T[] {
        const result: T[] = [];
        if (items) {
            for (let i = 0; i < items.length; i++) {
                /* eslint-disable new-cap */
                result.push(new type(items[i]));
                /* eslint-enable new-cap */
            }
        }
        return result;
    }

    static getPromiseResult<T>(value: T): Promise<T> {
        return new Promise<T>((resolve) => { resolve(value); });
    }

    //------------------------------
    //Object
    //------------------------------
    static getPropertyNames(obj: any): string[] {
        const result: string[] = [];
        if (!obj) return result;
        Object.getOwnPropertyNames(obj).forEach((prop) => {
            result.push(prop);
        });
        return result;
    }

    //------------------------------
    //File
    //------------------------------
    static imageFileExtensions: string[] = [".PNG", ".JPG", ".JPEG", ".GIF", ".BMP", ".EMF", ".EXIF", ".ICON", ".TIF", ".WMF"];

    static blobToBase64(file: Blob): Promise<string> {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    }

    static blobToBase64WithoutHeader(file: Blob): Promise<string> {
        return Base.blobToBase64(file)
            .then(dataUri => dataUri.split(",").pop());
    }

    static base64ToBlob(base64: string, contentType: string): Blob {
        const byteString = atob(base64);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        return new Blob([ab], { type: contentType });
    }

    static blobToFile(blob: Blob, fileName: string): File {
        const b = <any>blob;
        b.lastModified = (new Date()).getTime();
        b.name = fileName;
        return <File>b;
    }

    static getFileExtension(name: string): string {
        if (!name) return "";
        const index = name.lastIndexOf(".");
        if (index < 0) return "";
        return name.substring(index);
    }

    static isImageFile(file: File): boolean {
        const ext = Base.getFileExtension(file?.name ?? "").toUpperCase();
        return ext && Base.imageFileExtensions.indexOf(ext) > -1;
    }

    static removeDataUrlHeader(dataUrl: string): string {
        if (!dataUrl) return "";
        const result = /base64,(.+)/.exec(dataUrl);
        if (!result || result.length < 2) return dataUrl;
        return result[1];
    }

    //------------------------------
    //LocalStorage
    //------------------------------
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static getFromLocalStorage<T>(key: string, type: (new (...args: any[]) => T)): T | null {
        if (!key) return null;
        const item = localStorage.getItem(key);
        if (item === null) {
            return null;
        }
        let value: unknown;
        try {
            value = JSON.parse(item);
        } catch (error) {
            return null;
        }
        return new type(value);
    }

    static setToLocalStorage(key: string, value: unknown) {
        localStorage.setItem(key, JSON.stringify(value))
    }

    static removeFromLocalStorage(key: string) {
        localStorage.removeItem(key)
    }

    static strFormat(str: string, ...args: string[]): string {
        if (!str) return "";
        if (!args.length) return str;
        return str.replace(/{(\d+)}/g, (_, index) => args[index] || '')
    }
}