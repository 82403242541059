//import "office-js";
import { Base } from "../../framework/base";
import { IExcelFileSettings } from "./excelFileSettings";
import { ExcelHelper, NamedRange, NamedShape, NamedTable, NamedValue } from "./excelHelper";
import { ParameterData } from "./parameterData";
import { GetParameterDatasResult, IGetParameterDatasResult } from "./parameterDatasResult";

export class ParameterDataReader {
    escapeValue(value: string): string {
        if (Base.isNullOrUndefined(value)) return "";
        let result = "";
        for (const c of value) {
            let ec = c;
            if (c === '"') {
                ec = "&quot;";
            } else if (c === "'") {
                ec = "&apos;";
            } else if (c === "<") {
                ec = "&lt;";
            } else if (c === ">") {
                ec = "&gt;";
            } else if (c === "&") {
                ec = "&amp;";
            }
            result = result + ec;
        }
        return result;
    }

    getXmlElement(name: string, value: string, typeAttribute: string = ""): string {
        const validName = name ? name.replace(/ /gi, "_") : null;
        return (name ? "<" + validName + (typeAttribute ? " type='" + typeAttribute + "'" : "") + ">" : "") + (value ?? "") + (name ? "</" + validName + ">" : "");
    }

    async getVerticalRangeValues(context: Excel.RequestContext, range: Excel.Range, oneCellRanges: NamedRange[]): Promise<NamedValue[]> {
        const result: NamedValue[] = [];
        if (!range) return result;
        //Load cell range data
        const values = range.text;
        const cellRanges: Excel.Range[][] = [];
        for (let rowIndex = 0; rowIndex < values.length; rowIndex++) {
            const rowCellRanges: Excel.Range[] = [];
            for (let colIndex = 0; colIndex < Math.min(values[rowIndex].length, 2); colIndex++) {
                const cell = range.getCell(rowIndex, colIndex)
                cell.load("address");
                rowCellRanges.push(cell);
            }
            cellRanges.push(rowCellRanges);
        }
        await context.sync();
        let rowIndex = 0;
        for (const row of values) {
            let elementName = "";
            let elementValue = "";
            for (let colIndex = 0; colIndex < Math.min(row.length, 2); colIndex++) {
                if (colIndex === 0) {
                    elementName = row[colIndex];
                } else if (colIndex === 1) {
                    elementValue = this.escapeValue(row[colIndex]);
                    const cellRangeName = ExcelHelper.getCellRangeName(cellRanges[rowIndex][colIndex], oneCellRanges);
                    if (cellRangeName) {
                        elementName = cellRangeName;
                    }
                }
            }
            result.push(NamedValue.CreateNamedValue(elementName, elementValue))
            rowIndex = rowIndex + 1;
        }
        return result;
    }

    getXmlElementFromNamedValues(values: NamedValue[], rootElementName: string): string {
        let result = "";
        for (const value of values) {
            result = result + this.getXmlElement(value.name, value.value, value.base64Image ? "base64EncodedImage" : "");
        }
        return this.getXmlElement(rootElementName, result);
    }

    async getXmlElementFromVerticalRange(context: Excel.RequestContext, range: Excel.Range, rootElementName: string, oneCellRanges: NamedRange[]): Promise<string> {
        return this.getXmlElementFromNamedValues(await this.getVerticalRangeValues(context, range, oneCellRanges), rootElementName);
    }
    
    getXmlElemenFromHorizontalRange(range: Excel.Range, rootElementName: string, rowElementName: string,
        targetRowIndex: number = null, ignoreColumnNames: string[] = null): string {
        let result = "";
        const values = range.text;
        if (values.length < 2) return "";
        const titleRow = values[0];
        for (let rowIndex = 1; rowIndex < values.length; rowIndex++) {
            if (!!targetRowIndex && targetRowIndex !== rowIndex) continue;
            const row = values[rowIndex];
            let rowValues = "";
            for (let i = 0; i < row.length; i++) {
                if (ignoreColumnNames && ignoreColumnNames.findIndex(j => j === titleRow[i]) > -1) continue;
                rowValues = rowValues + this.getXmlElement(titleRow[i], this.escapeValue(row[i]));
            }
            result = result + Base.LineBreak + this.getXmlElement(rowElementName, rowValues);
        }
        return this.getXmlElement(rootElementName, result);
    }

    getSelectedRowIndexesFromHorizontalRange(range: Excel.Range): number[] {
        const result = [];
        const values = range.text;
        if (values.length < 2) return [];
        for (let rowIndex = 1; rowIndex < values.length; rowIndex++) {
            const row = values[rowIndex];
            if (!!row[row.length - 1]) {
                result.push(rowIndex);
            }
        }
        return result;
    }

    getColumnValueFromHorizontalRange(range: Excel.Range, rowIndex: number, columnName: string): string {
        const values = range.text;
        if (rowIndex < 1 || rowIndex > values.length-1) return "";
        const titleRow = values[0];
        const colIndex = titleRow.findIndex(i => i === columnName);
        if (colIndex < 0) return "";
        return values[rowIndex][colIndex];
    }

    async getRootXmlElement(context: Excel.RequestContext, range: Excel.Range, invalidRanges: NamedRange[], oneCellRanges: NamedRange[], allTables: NamedTable[], allShapes: NamedValue[], allCharts: NamedValue[]): Promise<string> {
        let values = await this.getVerticalRangeValues(context, range, oneCellRanges);
        const usedOneCellRangeNames = values.map(i => i.name);
        const unUsedOneCellRanges: { namedRange: NamedRange, interSections: Excel.Range[] }[] = [];
        for (const oneCellRange of oneCellRanges) {
            if (usedOneCellRangeNames.indexOf(oneCellRange.name) > -1) continue;
            unUsedOneCellRanges.push({ namedRange: oneCellRange, interSections: [] });
        }
        const tableHeaderRanges = allTables.map(i => ({ sheetName: i.sheetName, range: i.table.getHeaderRowRange() }));
        for (const unUsedOneCellRange of unUsedOneCellRanges) {
            for (const headerRange of tableHeaderRanges) {
                if (unUsedOneCellRange.namedRange.sheetName !== headerRange.sheetName) continue;
                const interSection = headerRange.range.getIntersectionOrNullObject(unUsedOneCellRange.namedRange.range);
                interSection.load("isNullObject")
                unUsedOneCellRange.interSections.push(interSection);
            }
        }
        await context.sync();
        const availableOneCellRanges: NamedRange[] = [];
        for (const unUsedOneCellRange of unUsedOneCellRanges) {
            if (unUsedOneCellRange.interSections.find(i => !i.isNullObject)) continue;
            availableOneCellRanges.push(unUsedOneCellRange.namedRange);
        }
        values = values.concat(invalidRanges.map(i => NamedValue.CreateNamedValue(i.name, "")));
        values = values.concat(availableOneCellRanges.map(i => NamedValue.CreateNamedValue(i.name, this.escapeValue(i.range.text[0][0]))));
        values = values.concat(allShapes);
        values = values.concat(allCharts);
        values.sort((a: NamedValue, b: NamedValue) => {
            if (!a || !b) return 0;
            return Base.strCompare(a.name, b.name);
        });
        return this.getXmlElementFromNamedValues(values, null);
    }

    async getSubXmlElementFromTable(context: Excel.RequestContext, table: NamedTable, oneCellRanges: NamedRange[], rootElementName: string): Promise<string> {
        let result = "";
        //Load table data
        const headerRange = table.table.getHeaderRowRange();
        headerRange.load("text");
        headerRange.load("rowCount");
        headerRange.load("columnCount");
        const bodyRange = table.table.getDataBodyRange();
        const bodyRowRanges: Excel.Range[] = [];
        const bodyColRanges: Excel.Range[] = [];
        bodyRange.load("text");
        for (let rowIndex = 0; rowIndex < table.table.rows.count; rowIndex++) {
            const bodyRowRange = bodyRange.getRow(rowIndex);
            bodyRowRange.load("rowHidden");
            bodyRowRanges.push(bodyRowRange);
        }
        for (let colIndex = 0; colIndex < table.table.columns.count; colIndex++) {
            const bodyColRange = bodyRange.getColumn(colIndex);
            bodyColRange.load("columnHidden");
            bodyColRanges.push(bodyColRange);
        }
        await context.sync();
        //Load cell range data
        const headerValues = headerRange.text;
        const cellRanges: Excel.Range[][] = [];
        const rowCellRanges: Excel.Range[] = [];
        for (let colIndex = 0; colIndex < headerValues[0].length; colIndex++) {
            const cell = headerRange.getCell(0, colIndex)
            cell.load("address");
            rowCellRanges.push(cell);
        }
        cellRanges.push(rowCellRanges);
        await context.sync();
        //Checks
        if (!bodyRowRanges.find(i => !i.rowHidden)) {
            console.warn("Table '" + table.table.name + "'' does not have any visible rows!")
            return "";
        }
        if (!bodyColRanges.find(i => !i.columnHidden)) {
            console.warn("Table '" + table.table.name + "'' does not have any visible columns!")
            return "";
        }
        if (headerRange.rowCount < 1 && headerRange.columnCount < table.table.columns.count) {
            console.warn("Table '" + table.table.name + "'' does not have valid header columns!")
            return "";
        }
        //Header columns
        const columnTitles: string[] = [];
        for (let colIndex = 0; colIndex < headerValues[0].length; colIndex++) {
            let columnTitle = headerValues[0][colIndex];
            const cellRangeName = ExcelHelper.getCellRangeName(cellRanges[0][colIndex], oneCellRanges);
            if (cellRangeName) {
                columnTitle = cellRangeName;
            }
            columnTitles.push(columnTitle);
        }
        //Value columns
        const bodyValues = bodyRange.text;
        for (let rowIndex = 0; rowIndex < bodyValues.length; rowIndex++) {
            const rowValues = bodyValues[rowIndex];
            if (bodyRowRanges[rowIndex].rowHidden) continue;
            let rowXmlValues = "";
            for (let colIndex = 0; colIndex < rowValues.length; colIndex++) {
                if (bodyColRanges[colIndex].columnHidden) continue;
                rowXmlValues = rowXmlValues + this.getXmlElement(columnTitles[colIndex], this.escapeValue(rowValues[colIndex]));
            }
            result = result + Base.LineBreak + this.getXmlElement(table.table.name, rowXmlValues);
        }
        return this.getXmlElement(rootElementName, result);
    }
    
    async getSubXmlElementsFromTables(context: Excel.RequestContext, tables: NamedTable[], oneCellRanges: NamedRange[], useCollectionElements: boolean): Promise<string> {
        let result = "";
        for (const table of tables) {
            result = result +  await this.getSubXmlElementFromTable(context, table, oneCellRanges, useCollectionElements ? table.table.name + "Collection" : null);
        }
        return result;
    }

    async getParameterDatas(rootName: string, omitElementsWithPrefix: string, useCollectionElements: boolean): Promise<IGetParameterDatasResult> {
        const result = new GetParameterDatasResult()
        try {
            return await Excel.run(async (context) => {
                const workbook = context.workbook;
                const worksheets = workbook.worksheets;
                const activeSheet = worksheets.getActiveWorksheet();
                workbook.load("names")
                worksheets.load("items")
                activeSheet.load("name");
                await context.sync();
                for (let i = 0; i < worksheets.items.length; i++) {
                    const sheet = worksheets.items[i];
                    sheet.load("name");
                    sheet.load("tables");
                    sheet.load("shapes");
                    sheet.load("charts");
                }
                await context.sync();
                const rootElementName = !rootName
                    ? activeSheet.name
                    : rootName;
                let allRanges: NamedRange[] = [];
                let allTables: NamedTable[] = [];
                let allShapes: NamedValue[] = [];
                let allCharts: NamedValue[] = [];
                for (let i = 0; i < workbook.worksheets.items.length; i++) {
                    const sheet = workbook.worksheets.items[i];
                    allRanges = allRanges.concat(await ExcelHelper.getAllNamedRanges(omitElementsWithPrefix, context, sheet, workbook.names.items));
                    allTables = allTables.concat(await ExcelHelper.getAllTables(omitElementsWithPrefix, context, sheet));
                    allShapes = allShapes.concat(await ExcelHelper.getAllShapes(omitElementsWithPrefix, context, sheet));
                    allCharts = allCharts.concat(await ExcelHelper.getAllCharts(omitElementsWithPrefix, context, sheet));
                }
                const invalidRanges = allRanges.filter(i => !i.range);
                const oneCellRanges = allRanges.filter(i => i.range && i.range.cellCount === 1);
                const parameterData = new ParameterData();
                const rootRange = allRanges.find(i => i.name === rootElementName);
                const xml = this.getXmlElement(rootElementName,
                    await this.getRootXmlElement(context, rootRange?.range, invalidRanges, oneCellRanges, allTables, allShapes, allCharts) +
                    await this.getSubXmlElementsFromTables(context, allTables, oneCellRanges, useCollectionElements)
                );
                parameterData.filename = rootElementName
                const prettifyResult = Base.prettifyXml(xml);
                parameterData.data = prettifyResult.xml;
                parameterData.error = prettifyResult.error;
                parameterData.errorData = prettifyResult.errorData;
                result.data.push(parameterData);
                return result;
            });
        } catch (error) {
            console.error("ERROR getParameterDatas: ", error);
            result.error = error;
            return result;
        }
    }
}