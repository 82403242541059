import { ApiResponse, IApiResponse } from "./apiResponse";

export interface IApiSaveSuccess extends IApiResponse {
    id: string;
}

export class ApiSaveSuccess extends ApiResponse implements IApiSaveSuccess {
    id: string;

    constructor();
    constructor(obj: IApiSaveSuccess);
    constructor(obj?: any) {
        super(obj);
        this.id = obj && obj.id || "";
    }
}