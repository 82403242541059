import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RootState, useAppDispatch } from "../../store";
import { authActions } from "../../store/auth";
import React from "react";
import { Translations } from "../../models/common/translations";
import { IDropdownOption, IconButton } from "@fluentui/react";
import FormLine from "../common/FormLine";
import TitleBar from "../common/TitleBar";
import { callService, callServiceOnSave, handleFormDialogClose, navigateBackWithReturnUrl } from "../../models/common/appUtils";
import { getOwnerListItems, setDefaultOwnerCode } from "../../models/services/ownerService";
import FormDropdown from "../common/FormDropdown";
import { Base } from "../../framework/base";
import { IOwnerListItem } from "../../models/owner";

//Styles

//Component
export const SelectOwner = () => {
    const dispatch = useAppDispatch();
    const orgOwnerCode = useSelector((state: RootState) => state.auth.authUser?.ownerCode);
    const navigate = useNavigate();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);
    const [ownerListItems, setOwnerListItems] = useState<IOwnerListItem[]>([]);
    const [owners, setOwners] = useState<IDropdownOption[]>([]);


    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            ownerCode: orgOwnerCode
        }
    });
    
    const { isDirty } = formState;

    const closeView = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    }

    const setActiveOwner = async (ownerCode: string) => {
        const ownerListItem = ownerListItems.find(i => i.code === ownerCode);
        if (!ownerListItem) return;
        dispatch(authActions.setOwner(ownerListItem));
        await callServiceOnSave(() => setDefaultOwnerCode(ownerCode), false);
    }

    const onSubmit = ({ ownerCode } : { ownerCode: string }) => {
        if (!ownerCode) return;
        const owner = owners.find(i => i.key === ownerCode);
        if (!owner) return;
        setActiveOwner(ownerCode);
        closeView();
    }

    const handleClose = async () => {
        handleFormDialogClose(isDirty, () => handleSubmit(onSubmit)(), closeView);
    }

    const loadInitialData = async () => {
        const newOwnerListItems = await callService(() => getOwnerListItems())
        if (!newOwnerListItems) return
        var options = newOwnerListItems.items.map(i => {
            return { key: i.code, text: i.name }
        });
        setOwnerListItems(newOwnerListItems.items);
        setOwners(options);
    }
    
    useEffect(() => {
        loadInitialData();
    }, []);

    useEffect(() => {
        if (owners.length === 1 && Base.isString(owners[0].key)) {
            setActiveOwner(owners[0].key);
            closeView();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [owners]);

    return (
        <div>
            <TitleBar
                title={Translations.SelectOwner}
            >
                {!!orgOwnerCode &&
                    <React.Fragment>
                        <IconButton iconProps={{ iconName: "Save" }} title={Translations.Save} ariaLabel={Translations.Save} onClick={handleSubmit(onSubmit)} />
                        <IconButton iconProps={{ iconName: "ChromeClose" }} title={Translations.Close} ariaLabel={Translations.Close} onClick={handleClose} />
                    </React.Fragment>
                }
            </TitleBar>
            <FormLine>
                <FormDropdown
                    label={Translations.Owner}
                    name="ownerCode"
                    control={control}
                    options={owners}
                />
            </FormLine>
        </div>
    )
}