import { Base } from "../../framework/base";
import { IUseCaseDataSetTableData, UseCaseDataSetTableData } from "./useCaseDataSetTableData";

export interface IUseCaseDataSetTableDatas {
    items: IUseCaseDataSetTableData[];
}

export class UseCaseDataSetTableDatas implements IUseCaseDataSetTableDatas {
    items: UseCaseDataSetTableData[];

    constructor();
    constructor(obj: IUseCaseDataSetTableDatas);
    constructor(obj?: any) {
        this.items = Base.getTypedArray(obj ? obj.items : null, UseCaseDataSetTableData);
    }
}
