import { DocumentCard, DocumentCardActions, DocumentCardDetails, DocumentCardTitle, IButtonProps, Label, Stack } from "@fluentui/react";
import * as React from "react";
import styled from "@emotion/styled";
import { Translations } from "../../models/common/translations";

//Styles
const StyledDocumentCard = styled(DocumentCard)`
    margin-top: 10px;
    height: 200px;
    max-width: 100%;
    display: flex;
    bottom: 0;
`

const StyledDocumentCardDetails = styled(DocumentCardDetails)`
    overflow: auto;
    padding: 5px;
`

//Styles
const TemplateDiv = styled.div(
    {
        width: 131,
        height: 200,
        marginLeft: 2,
        marginRight: 8,
        marginTop: 2,
        marginBottom: 8,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
    }
)

const Thumbnail = styled.div(
    {
        height: 185,
        marginBottom: 5,
        display: "flex",
        alignItems: "center",

        "&.withText": {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 185,
            border: "1px solid #333"
        },

        img: {
            border: "1px solid #333",
            objectFit: "contain",
            width: "100%"
        },

        div: {
            textAlign: "center"
        }
    }
)

//Component
export interface IDetailCardProps {
    id: string;
    title: string;
    description: string;
    image: string;
    actionItems: IButtonProps[];
}

export const DetailCard = (props: IDetailCardProps) => {
    const [actionItems, setActionItems] = React.useState<IButtonProps[]>([]);

    const handleActions = () => {
        setActionItems(props.actionItems);
    }

    React.useEffect(() => {
        handleActions();
    }, [props]);

    return (
        <StyledDocumentCard>
            <TemplateDiv>
                <Thumbnail className={props.image ? "" : " withText"} >{props.image
                    ? <img src={"data:image/jpg;base64," + props.image} alt={Translations.Thumbnail} ></img>
                    : <div><span>{Translations.ThumbnailIsNotAvailable}</span></div>}
                </Thumbnail>
            </TemplateDiv>
            <Stack style={{ flex: 1 }}>
                <DocumentCardTitle title={props.title} />
                <StyledDocumentCardDetails>
                    <Label>{Translations.Description}</Label>
                    <div>{props.description}</div>
                </StyledDocumentCardDetails>
                <DocumentCardActions actions={actionItems} />
            </Stack>
        </StyledDocumentCard>
    );
}

export default DetailCard;