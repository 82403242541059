import { useSelector } from "react-redux";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { RootState, useAppDispatch } from "../../store";
import { authActions } from "../../store/auth";
import React from "react";
import { Translations } from "../../models/common/translations";
import { IconButton, MessageBarType, Stack } from "@fluentui/react";
import FormTextField from "../common/FormTextField";
import MessageLine from "../common/MessageLine";
import FormLine from "../common/FormLine";
import TitleBar from "../common/TitleBar";
import { navigateBackWithReturnUrl, navigateWithSetReturnUrl } from "../../models/common/appUtils";
import SpinnerButton from "../common/SpinnerButton";

//Styles

//Component
export const Login = () => {
    const dispatch = useAppDispatch();
    const authUser = useSelector((state: RootState) => state.auth.authUser);
    const authError = useSelector((state: RootState) => state.auth.error);
    const navigate = useNavigate();
    const returnUrl = useSelector((state: RootState) => state.app.returnUrl);

    const handleClose = () => {
        navigateBackWithReturnUrl(navigate, returnUrl);
    }

    useEffect(() => {
        if (authUser) {
            handleClose();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);

    // get functions to build form with useForm() hook
    const { control, handleSubmit, formState } = useForm({
        defaultValues: {
            username: '',
            password: ''
        }
    });
    const { isSubmitting } = formState;

    function onSubmit({ username, password } : { username: string, password: string }) {
        return dispatch(authActions.login({ username, password }));
    }

    function onHideError() {
        return dispatch(authActions.hideError());
    }

    const handleSettings = () => {
        navigateWithSetReturnUrl(navigate, "/settings", "/login");
    }

    return (
        <div>
            <TitleBar
                title={Translations.Login}
            >
                <IconButton iconProps={{ iconName: "Settings" }} title={Translations.Settings} ariaLabel={Translations.ApplicationSettings} onClick={handleSettings} />
            </TitleBar>
            <FormLine>
                <FormTextField
                    label={Translations.Username}
                    name="username"
                    control={control}
                    required 
                    maxLength={50}
                    autoFocus                        
                    rules={{ required: Translations.UsernameIsRequired }}
                />
            </FormLine>
            <FormLine>
                <FormTextField
                    label={Translations.Password}
                    name="password"
                    control={control}
                    required 
                    maxLength={50}
                    type="password"
                    canRevealPassword                                          
                    rules={{ required: Translations.PasswordIsRequired }}
                />
            </FormLine>
            <FormLine horizontal horizontalAlign="center">
                <SpinnerButton
                    text={Translations.Login}
                    isSubmitting={isSubmitting}
                    onClick={handleSubmit(onSubmit)}
                />
            </FormLine>
            {!!(authError?.message) &&
                <Stack horizontal>
                    <MessageLine
                        text={authError.message}
                        type={MessageBarType.error}
                        onDismiss={onHideError}
                    />
                </Stack>
            }                
        </div>
    )
}