import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { IAuthUser } from "../../models/auth/authUser";
import { AuthUtil } from "../../models/auth/authUtil";
import { AuthResponse } from "../../models/auth/authResponse";
import { getErrorMessage } from "../../models/services/baseService";
import { initialLogin } from "../../models/services/tokenService";
import { callServiceFromThunk } from "../storeUtils";
import { IOwnerListItem } from "../../models/owner";

export interface IAuthState {
    authUser: IAuthUser | null;
    error: { message: string | null } | null;
}

const setAuthData = (state: IAuthState, data: AuthResponse) => {
    state.authUser = {
        id: data.id,
        name: data.name,
        ownerCode: data.ownerCode,
        ownerName: data.ownerName,
        authorizationLevel: data.authorizationLevel,
        hasMultipleOwners: data.hasMultipleOwners,
        token: data.token,
        refreshToken: data.refreshToken
    };
    AuthUtil.setTokens(data.token, data.refreshToken, data.ownerCode);
    AuthUtil.setAuthToLocalStorage(state.authUser);
}

const removeAuthData = (state: IAuthState) => {
    state.authUser = null;
    AuthUtil.setTokens("", "", "");
    AuthUtil.removeAuthFromLocalStorage();
}

const authSlice = createSlice({
    name: "auth",
    initialState: createInitialState(),
    reducers: {
        logout(state: IAuthState) {
            removeAuthData(state);
        },
        update(state: IAuthState, action: PayloadAction<AuthResponse>) {
            const data = action.payload;
            setAuthData(state, data);
        },
        hideError(state: IAuthState) {
            state.error = null;
        },
        setOwner(state: IAuthState, action: PayloadAction<IOwnerListItem>) {
            const data = action.payload;
            setAuthData(state, { ...state.authUser, ownerCode: data.code, ownerName: data.name, authorizationLevel: data.authorizationLevel });
        },
    },
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(login.pending, (state) => {
            state.error = null;
        });
        builder.addCase(login.fulfilled, (state, action) => {
            const data: AuthResponse | undefined = action.payload;
            if (data?.id && data?.name && data?.token && data?.refreshToken) {
                setAuthData(state, data);
            }
        });
        builder.addCase(login.rejected, (state, action) => {
            state.error = { message: getErrorMessage(action.payload) };
            removeAuthData(state);
        });
    },
});

function createInitialState(): IAuthState {
    const authUser = AuthUtil.getAuthFromLocalStorage();
    if (authUser) {
        AuthUtil.setTokens(authUser.token, authUser.refreshToken, authUser.ownerCode);
    }
    return {
        authUser: authUser
            ? { 
                id: authUser.id,
                name: authUser.name,
                ownerCode: authUser.ownerCode,
                ownerName: authUser.ownerName,
                authorizationLevel: authUser.authorizationLevel,
                hasMultipleOwners: authUser.hasMultipleOwners,
                token: authUser.token,
                refreshToken: authUser.refreshToken
            }
            : null,
        error: null
    }
}

const login = createAsyncThunk(
    "auth/login",
    async (data: { username: string, password: string }, { rejectWithValue }) => { 
        return callServiceFromThunk(() => initialLogin(data.username, data.password), rejectWithValue);
    }
);

export const authActions = { ...authSlice.actions, login };
export const authReducer = authSlice.reducer;
export default authSlice.reducer;
