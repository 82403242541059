import { Base } from "../../framework/base";
import { IParameterData, ParameterData } from "./parameterData";

export interface IGetParameterDatasResult {
    data: IParameterData[];
    error: any;
}

export class GetParameterDatasResult implements IGetParameterDatasResult {
    data: ParameterData[];
    error: any;

    constructor();
    constructor(obj: IGetParameterDatasResult);
    constructor(obj?: any) {
        this.data = Base.getTypedArray(obj ? obj.data : null, ParameterData);
        this.error = obj && obj.error || "";
    }
}