import * as React from "react";
import { FontIcon, IFontIconProps } from "@fluentui/react";
import styled from "@emotion/styled";

//Styles
const StyledDiv = styled.div<IFileIconButtonProps>`
    cursor: ${(props) => !props.disabled && "pointer"};
    color: rgb(0, 120, 212);
    &:hover {
        color: rgb(16, 110, 190);
        background-color: rgb(243, 242, 241);
    }
`

const StyledIcon = styled(FontIcon)`
    margin: 8px;
`

//Component
export interface IFileIconButtonProps {
  accept?: string;
  disabled?: boolean;
  name?: string;
  onFileChange?: (file: File) => void;
}

export const FileIconButton = (props: IFileIconButtonProps & IFontIconProps) => {
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.disabled || !e.target.files || e.target.files.length < 1) return;
    const file = e.target.files[0];
    props.onFileChange(file);
  };

  return (
    <label>
      <StyledDiv title={props.title}>
        <StyledIcon
          className="icon-113"
          iconName="Upload"
        />
        {!props.disabled &&
          <input type="file" name={props.name} className="file" style={{ display: "none" }} multiple={false} onChange={handleFileChange} accept={props.accept} />
        }
      </StyledDiv>
    </label>
  );
}

export default FileIconButton;