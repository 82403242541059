import { IPivotStyleProps, IPivotStyles, IStyleFunctionOrObject, PartialTheme } from "@fluentui/react";

//Theme
export const appTheme: PartialTheme = {
  semanticColors: {
    primaryButtonBackground: "#FBC010",
    primaryButtonText: "#333",
    primaryButtonBackgroundHovered: "#ecb204",
    primaryButtonTextHovered: "#333"
  }
};

export const pivotStyles: IStyleFunctionOrObject<IPivotStyleProps, IPivotStyles> = {
  itemContainer: {
      paddingTop: 10
  },
  linkIsSelected: {
      selectors: {
        ':before': {
          backgroundColor: appTheme.semanticColors.primaryButtonBackground
        }
      }
  }
}