import * as React from "react";
import { Base } from "./../../framework/base";
import { Image, IImageProps } from "@fluentui/react";
import { Translations } from "../../models/common/translations";
import { showErrorMessage } from "../../models/common/appUtils";
import styled from "@emotion/styled";

//Style
const StyledLabel = styled.label<IFormImageFieldProps>`
    cursor: ${(props) => !props.disabled && "pointer"};
    box-shadow: 1px 1px lightgray;
`

const StyledImage = styled(Image)`
    border: solid 1px black;
`

//Component
export interface IFormImageFieldProps {
    disabled?: boolean;
    name?: string;
    onFileChange?: (file: File) => void;
}

export const FormImageField = (props: IFormImageFieldProps & IImageProps) => {
    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (props.disabled || !e.target.files || e.target.files.length < 1) return;
        const file = e.target.files[0];
        if (Base.isImageFile(file)) {
            props.onFileChange(file);
        } else {
            showErrorMessage(Translations.SelectImageFile);
        }
    };
    
    return (
        <div>
            <StyledLabel>
                <StyledImage
                    {...props}
                />
                {!props.disabled &&
                    <input type="file" name={props.name} className="file" style={{ display: "none" }} multiple={false} onChange={handleFileChange} accept={Base.imageFileExtensions.join(",")} />
                }
            </StyledLabel>
        </div>
    );
}
