import { IUseCaseDataSetEditItem, UseCaseDataSetEditItem } from "./useCaseDataSetEditItem";

export interface IUseCaseDataSetEdit {
    item: IUseCaseDataSetEditItem;
}

export class UseCaseDataSetEdit implements IUseCaseDataSetEdit {
    item: UseCaseDataSetEditItem;

    constructor();
    constructor(obj: IUseCaseDataSetEdit);
    constructor(obj?: any) {
        this.item = new UseCaseDataSetEditItem(obj?.item);
    }
}
