import * as React from "react";
import {
    DrawerBody,
    DrawerHeader,
    OverlayDrawer,
} from "@fluentui/react-drawer";
import { IconButton, PrimaryButton } from "@fluentui/react";
import { Translations } from "../../models/common/translations";
import styled from "@emotion/styled";
import { ReactNode } from "react";

const StyledDrawer = (props) => (
    <OverlayDrawer
        {...props}
        style={{
            height: '80%',
            backgroundColor: '#fff',
            padding: '0px 10px 10px 10px'
        }}
    />
);

const StyledDrawerBody = (props) => (
    <DrawerBody
        {...props}
        style={{
            overflow: "hidden"
        }}
    />
);

const TitleBarDiv = styled.div(
    {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
    }
)
const ButtonDiv = styled.div(
    {
        marginTop: "20px"
    }
)

//Component
interface IBottomDraver {
    buttonTitle: string;
    title?: string;
    children?: ReactNode;
}

export const BottomDrawer = (props: IBottomDraver) => {
    const [isOpen, setIsOpen] = React.useState(false);

    const onClickBottomButton = React.useCallback(() => {
        setIsOpen(true);
    }, []);

    return (
        <div>
            <StyledDrawer
                position={"bottom"}
                open={isOpen}
                onOpenChange={(_, { open }) => setIsOpen(open)}
            >
                <DrawerHeader>
                    <TitleBarDiv>
                        <h3>{props.title ?? ""}</h3>
                        <IconButton
                            iconProps={{ iconName: "ChromeClose" }}
                            title={Translations.Close}
                            ariaLabel={Translations.Close}
                            onClick={() => setIsOpen(false)} />
                    </TitleBarDiv>
                </DrawerHeader>
                <StyledDrawerBody >
                    {props.children}
                </StyledDrawerBody>
            </StyledDrawer>
            <ButtonDiv>
                <PrimaryButton onClick={onClickBottomButton}>
                    {props.buttonTitle}
                </PrimaryButton>
            </ButtonDiv>
        </div>
    );
};