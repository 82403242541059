import { jwtDecode } from "jwt-decode";
import { Base } from "../../framework/base";
import { AuthUser, IAuthUser } from "./authUser";

const localStorageKeyAuth = "aiddoOfficeAuth";

export class AuthUtil {
    static token: string;
    static refreshToken: string;
    static ownerCode: string;

    static setTokens(token: string, refreshToken: string, ownerCode: string) {
        AuthUtil.token = token;
        AuthUtil.refreshToken = refreshToken;
        AuthUtil.ownerCode = ownerCode;
    }

    static getAuthFromLocalStorage(): IAuthUser | null {
        const authUser = Base.getFromLocalStorage(localStorageKeyAuth, AuthUser);
        if (AuthUtil.tokenIsExpired(authUser?.token)) return null;
        return authUser;
    }

    static removeAuthFromLocalStorage() {
        Base.removeFromLocalStorage(localStorageKeyAuth);
    }
    
    static setAuthToLocalStorage(authUser: IAuthUser | null) {
        if (!authUser) {
            AuthUtil.removeAuthFromLocalStorage();
        } else {
            Base.setToLocalStorage(localStorageKeyAuth, authUser);
        }
    }

    static authNeedsRefresh(): boolean {
        if (!AuthUtil.token) return false;
        const currentTime = (new Date()).getTime();
        const decoded = jwtDecode(AuthUtil.token);
        if (!decoded.iat) return false;
        const durationMin = (currentTime - decoded.iat * 1000) / (60 * 1000);
        return durationMin > 60; //Refresh token once in hour
    }

    static tokenIsExpired(token: string | undefined): boolean {
        if (!token) return true;
        const decoded = jwtDecode(token);
        return !decoded.exp || Date.now() > decoded.exp * 1000;
    }
}