import { Action, ThunkAction, combineReducers, configureStore } from "@reduxjs/toolkit";
import { app } from "./app";
import { auth } from "./auth";
import { template } from "./template";
import { useCase } from "./useCase";
import { useDispatch } from "react-redux";

const rootReducer = combineReducers({ app, auth, template, useCase });

const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch; // Export a hook that can be reused to resolve types
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action>;

export default store;
