//Content types
export const docxContentType = "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
export const xlsxContentType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
export const pdfContentType = "application/pdf";
//GenerationParameters properties
export const generationParameterContextKey = "CallContext";
export const generationParameterTemplateGroupKey = "TemplateGroup";
export const generationParameterRootElementNameKey = "RootElementName";
export const generationParameterOmitElementsWithPrefixKey = "OmitElementsWithPrefix";
export const generationParameterUseCollectionElementsKey = "UseCollectionElements";
export const generationParameterAllowMultipleKey = "AllowMultiple";
export const generationParameterAutoOpenTaskPaneKey = "AutoOpenTaskPane";
