import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import React from "react";
import { RootState } from "../../store";
import { hasPermission } from "../../models/common/appUtils";
import { AuthorizationLevel } from "../../models/common/enums";

export function AuthorizationLevelReaderRoute() {
    const authUser = useSelector((state: RootState) => state.auth.authUser);

    return hasPermission(authUser, AuthorizationLevel.Reader)
        ? <Outlet />
        : <Navigate to="/default" />;
}
