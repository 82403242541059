export const enum DialogType {
    Info = "info",
    Warning = "warning",
    Error = "wrror"
}

export enum ConfirmationDialogResult {
    Yes,
    No,
    Cancel
}

export const enum TemplateThumbnailSize {
    Small = "Small",
    Medium = "Medium",
    Large = "Large"
}

export const enum LanguageCode {
    Fi = "fi",
    Sv = "sv",
    En = "en",
    Ja = "ja",
    De = "de",
    Fr = "fr",
    Es = "es",
    Pt = "pt",
    It = "it",
    Nl = "nl",
    Da = "da",
    No = "no",
    Ru = "ru",
    Et = "et",
    Lv = "lv",
    Lt = "lt",
    Pl = "pl",
    Ot = "ot"
}

export const enum DocumentFormat {
    Docx = "docx",
    Pdf = "pdf",
    Html = "html",
    Xlsx = "xlsx",
    Pptx = "pptx"
}

export const enum DebugDataType {
    None = "None",
    Use = "Use"
}

export const enum MetadataReturnType {
    None = "None",
    Document = "Document",
    Response = "Response",
    DocumentAndResponse = "DocumentAndResponse"
}

export const enum ContentControlsHandlingType {
    Application = "Application",
    Remove = "Remove",
    Keep = "Keep"
}

export const enum AuthorizationLevel
{
    Admin,
    User = 2,
    Reader = 4,
    Sandbox = 6
}

export const enum TemplateFormat
{
    Docx,
    Xlsx
}

export const enum TemplateStateCode
{
    Archived = "ARCHIVED",
    Created = "CREATED",
    Ready = "READY",
    Published = "PUBLISHED"
}

export const enum TemplateTypeClass
{
    DocumentModel,
    ExcelWorkBookModel,
    FragmentPage,
    FragmentPageWithLayout,
    FragmentTableRow,
    FragmentInlineText,
    FragmentPageWithLayoutPlaceholder,
    BaseOwnerPartReference
}
